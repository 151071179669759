import React, { useState, useContext  } from 'react'
import "./Login.css"
import {useNavigate } from "react-router-dom"
import * as msal from '@azure/msal-browser';
import {scopes, homeAccountId,msalInstance } from "./config";
import LoginImage from "./LoginImage.jpg"
import useDashboardContext from '../../Contexts/DashboardContext';
import { saveUser } from '../../apiServices/dashboard';

import { v4 as uuidv4 } from 'uuid'
// require('dotenv').config()
// const basePath = process.env.BASE_PATH


function Login({setIsUserLoggedIn}) {
    const navigate = useNavigate();

    const {addUser} = useDashboardContext()
    const user_id =uuidv4().replace(/-/g, "")
    const handleLogin = async () => {
        try {
            const msalRes = await msalInstance.loginPopup({
                scopes: scopes,
                prompt: 'select_account',
            });
            const currentAccount = await msalInstance.getAccountByHomeId(homeAccountId);

            localStorage.setItem("isAuthenticated",JSON.stringify({isAuthenticated : true}))
            localStorage.setItem("user",JSON.stringify({
                name : msalRes.account.name,
                username : msalRes.account.username
            }))
            setIsUserLoggedIn(true)
            addUser({
                name : msalRes.account.name,
                username : msalRes.account.username,
                accessToken : msalRes.accessToken
            })
            saveUser({
                email : msalRes.account.username,
                username : msalRes.account.name,
                token : msalRes.accessToken,
                user_id : user_id
            })
            navigate('/dashboard')
        } catch (err) {
            console.log("error:::::::", err)
        }
    };
    return (
        <div className='loginContainer'>
            <div className='loginInnerContainer1'>
                <div className='LoginBody'>
                    <button onClick={()=>handleLogin()} className='LoginBodyButton'>LOGIN</button>
                </div>
            </div >
            <div className='loginInnerContainer2'>
                <img src={LoginImage} height="70%" width="70%"/>
            </div>
        </div>
    )
}

export default Login