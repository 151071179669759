import { createSlice } from "@reduxjs/toolkit";

const graphSlice = createSlice({
  name: "graph",
  initialState: {
    nodes: [],
    relationships: [],
    fixedNodes: [],
    neofourResultData: {},
    nodeNames: [],
  },
  reducers: {
    setNodes(state, action) {
      state.nodes = action.payload;
    },
    setRelationships(state, action) {
      state.relationships = action.payload;
    },
    setFixedNodes(state, action) {
      state.fixedNodes = action.payload;
    },
    resetNodes(state) {
      state.nodes = state.fixedNodes;
    },
    setNeofourResultData(state, action) {
      state.neofourResultData = action.payload;
    },
    setNodeNames(state, action) {
      state.nodeNames = action.payload;
    },
  },
});

export const graphActions = graphSlice.actions;

export default graphSlice;
