import EChartsReact from "echarts-for-react";
import * as echarts from "echarts";
import {
  setBarChartOption,
  setOption,
  setStackAreaChartOption,
} from "../../services/ChartService";
import { generateDataForBarChart } from "../../services/DataService";

function DefaultGradientStackedChart(props) {
  let chartModelColor = [];
  chartModelColor = props.chartTheme;
  var option = {};
  var chartType = "line";
  var title = "";
  var splitLineColorTheme = props.theme === "light" ? "#4F4F7935" : "#9090BB35";
  var titleColorTheme = props.theme === "light" ? "#4F4F79" : "#9090BB";

  var colorAreaStyle = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: chartModelColor[0].mainColor,
    },
    {
      offset: 1,
      color: chartModelColor[0].gradientColor,
    },
  ]);
  var barBorderRadius = [50, 50, 0, 0];
  var XaxisChartName = props.data.chartProps.xAxis;

  let yAxisData = {
    axisLine: {
      lineStyle: {
        color: "#757595",
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.25,
        color: props.theme === "light" ? "#4F4F7935" : "#9090BB35",
      },
    },
  };
  var chartData = props.data.data ? props.data.data : [{}];
  function initData() {
    var obj = generateDataForBarChart(chartData, props.data.chartProps.xAxis);
    var dataArray = chartData.map((item) => {
      return { name: item.type, value: item.value };
    });
    var axisLabel = {
      show: true,
      interval: 0,
      overflow: obj.categories.length < 5 ? "auto" : "truncate",
      width: 30,
    };
    option = setStackAreaChartOption(
      obj.categories,
      XaxisChartName,
      yAxisData,
      splitLineColorTheme,
      chartType,
      dataArray,
      title,
      titleColorTheme,
      barBorderRadius,
      colorAreaStyle,
      axisLabel
    );
  }

  initData();
  function onChartClick(params, echarts) {
    var obj = { [props.data.chartProps.xAxis]: params.name };
    props.filterChart(obj);
  }
  return (
    <>
      {props.editMode ? (
        <EChartsReact
          option={option}
          onEvents={{
            click: onChartClick,
          }}
        style={{ height: "100%" }}

        />
      ) : (
        <EChartsReact option={option} 
        style={{ height: "100%" }}
        />
      )}
    </>
  );
}

export default DefaultGradientStackedChart;
