import React, { useState, useRef } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { PiShareDuotone } from "react-icons/pi";
import CloseIcon from "@mui/icons-material/Close";
import { AiFillCopy } from "react-icons/ai";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import "../Sidebar/Sidebar.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    width: "50%",
    maxWidth: "none",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogBox() {
  const [open, setOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const inputRef = useRef(null);
  const preRef = useRef(null);

  const handleClick = () => {
    setOpen(true);
    const dashName = JSON.parse(localStorage.getItem("dashboardTabs"));
    dashName.forEach((dash) => {
      if (dash.isActive) {
        const url = window.location.href;
        const tabUrl = `${url}/view/id?value=${dash._id}`;
        setCurrentUrl(tabUrl);
      }
    });
  };

  const copyUrl = () => {
    setOpen(false);
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
    }
    toast.success("Copied Shared dashboard url", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };

  const copyIframeCode = () => {
    setOpen(false);
    if (preRef.current) {
      const range = document.createRange();
      range.selectNode(preRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    }
    toast.success("Copied Shared dashboard iframe code", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };

  const iframeCode = `
    <iframe
    title="Embedded Dashboard"
    src="${currentUrl}"
    style={{ width: '100%', height: '100vh', border: 'none' }}
  ></iframe>
  `;

  return (
    <>
      <div
        variant="outlined"
        onClick={handleClick}
        className="sidebarInnerContainer1 sidebarInnerContainer"
      >
        <span title="Share Dashboard">
          <PiShareDuotone className="sidebarInnerContainerIcon" />
        </span>
        <div>Shared Dashboard</div>
      </div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
        ></BootstrapDialogTitle>
        <DialogContent style={{ paddingTop: "7px" }}>
          <div style={{ display: "flex" }}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={currentUrl}
              inputRef={inputRef}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button onClick={copyUrl} title="Copy url">
              <AiFillCopy style={{ fontSize: "1.5rem" }} />
            </Button>
          </div>
          <div style={{ display: "flex" }}>
            <pre
              ref={preRef}
              style={{ border: "1px solid #c4c4c4", borderRadius: "5px" }}
            >
              {iframeCode}
            </pre>
            <Button onClick={copyIframeCode} title="Copy Code">
              <AiFillCopy style={{ fontSize: "1.5rem" }} />
            </Button>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
