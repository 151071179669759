import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
//const BASE_PATH = "http://127.0.0.1:5001/api/v1"
//const Path = "http://127.0.0.1:5001/api/v1"
const BASE_PATH = "https://dadashboardapi.affablebpm.com/api/v1"
const Path = "https://dadashboardapi.affablebpm.com/api/v1"

export const saveUser = async (userData) => {
  const apiEndpoint = `${BASE_PATH}/datasources/add/user`;
  const response = await fetch(apiEndpoint, {
      method: 'POST',  // You can adjust the method based on your API requirements
      headers: {
          'Content-Type': 'application/json',
          // Add any other headers needed for your API
      },
      body: JSON.stringify(userData),
  })
  console.log("adduser call API",userData);
  return await response.json()
}

export const getUser = async (userData) => {
  const apiEndpoint = `${BASE_PATH}/datasources/add/getuser`;
  const response = await fetch(apiEndpoint, {
      method: 'POST',  // You can adjust the method based on your API requirements
      headers: {
          'Content-Type': 'application/json',
          // Add any other headers needed for your API
      },
      body: JSON.stringify(userData),
  })
  console.log("Getuser call API",userData);
  return await response.json()
}

export const saveDashboard = async (userData, dbName, data, layouts) => {

  const url = `${BASE_PATH}/datasources/dashboard/save`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username : userData.name,
      email: userData.username,
      dashboard_name: dbName,
      childElements: data,
      layouts: layouts,
    })
  });

  return await response.json();
};

export const getQuestions = async () => {
  return []
  const defaultQuestionUrl =
    `${Path}/openai/get/default/question`;

  const response = await fetch(defaultQuestionUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ questionQuantity: 10 }),
  });

  return await response.json();
};

export const getDashData = async (userData, current_dashboard) => {
  const fetchUrl = `${BASE_PATH}/datasources/dashboard/data/one`;

  const response = await fetch(fetchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username: userData.name, email : userData.username, dashboard_name: current_dashboard.name, dashboard_id : current_dashboard.id }),
  });
  const res = await response.json()
console.log('resssssss', res)
  return res[0];
};

export const createDashboard = async (user, newDashboardName, dashboard_id) => {
  const url = `${BASE_PATH}/datasources/dashboard/create`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: user.username,
      username : user.name,
      dashboard_name: newDashboardName,
      childElements: [],
      layouts: {
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: [],
      },
      dashboard_id : dashboard_id
    }),
  });

  return await response.json();
};

export const deleteDashboard = async (dashboardID) => {
  const isDeleted = await fetch(`${Path}/dashboard/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: dashboardID,
    }),
  });

  return await isDeleted.json();
};

export const getQueryData = async (question) => {
  const url = `${BASE_PATH}/datasources/user-query`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ Question: question }),
  });

  return await response.json();
};

export const getChartHeading = async (question) => {
  const url = `${Path}/openai/get/heading`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ question: question }),
  });

  return await response.json();
};

export const getChartType = async (data, question) => {
  const url = `${Path}/openai/chart/type`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: data, question: question }),
  });

  return await response.json();
};

export const generateUniqueQuestion = async (questions) => {
  const uniqueQuestionUrl =
    `${Path}/openai/generate/unique/question`;

  const response = await fetch(uniqueQuestionUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ existingQuestion: questions }),
  });

  return await response.json();
};

export const getDefaultAiQuestions = async (quantity) => {
  const defaultQuestionUrl =
     `${Path}/openai/get/default/question`;

  const defaultQuestion = await fetch(defaultQuestionUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ questionQuantity: quantity }),
  });

  return await defaultQuestion.json();
};

export const getDashboardName = async (
  questions,
  remainingDashboard,
  dbNames
) => {
  const url = `${Path}/openai/get/dashboard/name`;


  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      question: questions,
      remainingDashboardNames: remainingDashboard,
      dashboardNames: dbNames,
    }),
  });

  return await response.json();
};

export const createAiDashboard = async (userName, aiDashboardName) => {
  const url = `${Path}/dashboard/create`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userName: userName,
      dashName: aiDashboardName,
      childElements: [],
      layouts: {
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: [],
      },
    }),
  });

  return await response.json();
};

export const saveDashboardData = async (
  userName,
  aiDashboardName,
  currentData,
  newLayout
) => {
  const Saveurl = `${Path}/dashboard/save`;

  const saveResponse = await fetch(Saveurl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userName: userName,
      dashName: aiDashboardName,
      childElements: currentData,
      layouts: newLayout,
    }),
  });

  return await saveResponse.json();
};

export const getRemainingDashboardNames = async (userData) => {
  const remainingTabsUrl = `${BASE_PATH}/datasources/dashboard/names`;

  const getRemainingTabsUrl = await fetch(remainingTabsUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: userData.name,
      email : userData.username
    }),
  });

  return await getRemainingTabsUrl.json();
};
export const addAuditLog = async (question,deptId,orgName,userid,requesturl) => {
  const baseUrl = `https://${requesturl}`;
  const addAuditLogUrl = `${baseUrl}/AffableBPM/AuditLogEvent`;
  console.log("userid",userid)
  const auditlogresult = await fetch(addAuditLogUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      CorelationId: uuidv4(), 
      Query: question, 
      DeptId: deptId, 
      orgname: orgName,
      UserId:userid 
    }),
  });
  const auditresult =await auditlogresult.json();
  console.log("Auditlog",auditresult)
  return auditresult;
};
const key = CryptoJS.enc.Utf8.parse('abcdefghijklmnop'); 
const decryptString = (encrypted) => {
  console.log("encrypted",encrypted.replace(/ /g, '+'))
  let str =encrypted.replace(/ /g, '+')
  let decryptedBytes = CryptoJS.AES.decrypt(str, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
  return decryptedBytes.toString(CryptoJS.enc.Utf8);
};
//api for neo4j data
export const getNeoForeQueryData = async (question,deptid) => {
  const url = 'https://pd-da-be.proudmeadow-e6787ba5.westus2.azurecontainerapps.io/api/v1/sql/chat';
  const urlParams = new URLSearchParams(window.location.search);
  const encrypted = urlParams.get('encrypted'); 
  const decryptedMessage = decryptString(decodeURIComponent(encrypted));
  console.log("decrypt",decryptedMessage);
  const params = {};
decryptedMessage.split('&').forEach(param => {
    const [key, value] = param.split('=');
    params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
});
  const deptId  = deptid;
  const siteName = params['sitename']; 
  const userid = params['userid']; 
  let orgName = params['orgname']; 
  const username = params['user']; 
  if (orgName == 'demo') {
    orgName = 'demoicg';
  }
  console.log("Deptid",deptId);
  addAuditLog(question,deptId, orgName,userid,siteName);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({  "query": question,
      "dept_id": deptId,
      "org_id": 0,
      "customer_id": 0,
      "user_id": 0,
      "org_name": orgName,
      "site_name": siteName,
      "limit": 0 }),
  });

  const res = await response.json()

  console.log("res:::::::::::::",res?.chart_res?.result)
  return res?.chart_res?.result
};
