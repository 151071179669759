import EChartsReact from "echarts-for-react";
import { setOption } from "../../services/ChartService";
import { generateDataForBarChart } from '../../services/DataService'

export default function BarWithBackground(props) {
  var option = {};
  var chartType = 'bar';
  var title ="";
  var backgroundStyleColor = 'rgba(180, 180, 180, 0.2)';
  var showBackground = true;
  var chartData = props.chartData;
  let yAxisData = {
    splitLine:{
        show:true,
        lineStyle: {
          opacity: 0.25,
          color: props.theme === 'light' ? '#4F4F7935' : '#9090BB35',
  
           }
      }
  }
      function initData(){
          var obj = generateDataForBarChart(chartData, props.data.chartProps.xAxis);
          option = setOption(obj.categories, yAxisData, chartType, obj.dataArray, title,"", backgroundStyleColor, showBackground);
      };
      
      initData();


  return (
    <>
      <EChartsReact option={option} 
        style={{ height: "100%" }}
        />
    </>
  );
}
