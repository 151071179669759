export const initialState = {
    dashboardData : null,
    userData : {},
    isDashboardSaved : false
}

const dashboardReducer = (state,action) =>{
    const {type,payload} = action
    switch(type){
        case "SAVE_DASHBOARD" : {
            return {
                ...state,
                dashboardData : payload.dashboardData
            }
        }
        case "ADD_USER": {
            console.log("payload:::::::", payload);
            return {
              ...state,
              userData: {
                ...state.userData,
                ...payload.userData
              }
            };
        }
        case "SAVE_BUTTON_CLICKED" : {
            return {
                ...state,
                isDashboardSaved : payload.isDashboardSaved
            }
        }
        default:
            return state;
    }
}

export default dashboardReducer