import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
// import { BrowserRouter as Router } from "react-router-dom";
import { Auth0ProviderWithHistory } from "./component/login/Auth";
import { Provider } from "react-redux";
// import store from "./reduxStore/store";

import { DashboardProvider } from "./Contexts/DashboardContext";

createRoot(document.getElementById("root")).render(
  <DashboardProvider>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
  </DashboardProvider>
);
