import EChartsReact from "echarts-for-react";
import { generateDataForDrillDOwn } from "../../services/DataService";
import { setOption } from "../../services/ChartService";
import * as echarts from "echarts";
export default function BarDrillDown(props) {
  let chartModelColor = [];
  chartModelColor = props.chartTheme;
  var option = {};
  var chartType = "bar";
  var title = "";
  var titleColorTheme = props.theme === "light" ? "#4F4F79" : "#9090BB";

  var drilldownData = [];
  var axisLabel = {
    show: true,
    interval: 0,
    rotate: 45,
  };
  let yAxisData = {
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.25,
        color: props.theme === "light" ? "#4F4F7935" : "#9090BB35",
      },
    },
  };
  var itemStyleColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: chartModelColor[0].mainColor,
    },
    {
      offset: 1,
      color: chartModelColor[0].gradientColor,
    },
  ]);
  var obj = {};
  var chartData = props.data.data ? props.data.data : [{}];
  function initData() {
    obj = generateDataForDrillDOwn(
      chartData,
      props.data.chartProps.xAxis,
      props.data.chartProps.series
    );
    var dataArray = chartData.map((item) => {
      return { name: item.type, value: item.value };
    });
    drilldownData = obj.drilldownData;
    option = setOption(
      titleColorTheme,
      obj.categories,
      yAxisData,
      chartType,
      dataArray,
      title,
      "",
      "",
      "",
      itemStyleColor,
      props.theme
    );
  }

  initData();

  function onChartClick(params, echarts) {
    if (params.data) {
      // let objDtata = { [props.data.chartProps.xAxis]: params.name };
      // props.filterChart(objDtata);
      var subData = drilldownData.find(function (data) {
        return data.dataGroupId === params.data.groupId;
      });
      if (!subData) {
        return;
      }
      echarts.setOption({
        xAxis: {
          data: subData.data.map(function (item) {
            return item[0];
          }),
        },
        series: {
          type: "bar",
          dataGroupId: subData.dataGroupId,
          data: subData.data.map(function (item) {
            return item[1];
          }),
        },
        graphic: [
          {
            type: "text",
            left: 50,
            top: 20,
            invisible: true,
            style: {
              text: "Back",
              fontSize: 30,
            },
            onclick: function () {
              echarts.setOption(option);
            },
          },
        ],
      });
    }
  }
  return (
    <>
      <EChartsReact
        option={option}
        onEvents={{
          click: onChartClick,
        }}
        style={{ height: "100%" }}
      />
    </>
  );
}
