import React, { useEffect,useRef } from 'react'
import { Tooltip } from "@mui/material";

function ExtendedSidebar({ setIsNewSidebarOpen, isNewSidebarOpen, remainingDashboard, handleModelOpen }) {
    const openDashboard = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (openDashboard.current && !openDashboard.current.contains(event.target)) {
                setIsNewSidebarOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className={isNewSidebarOpen ? 'extendedSidebarContainer' : ' extendedSidebarContainer hideExtendedSidebar'} onMouseEnter={() => setIsNewSidebarOpen(true)} onMouseLeave={() => setIsNewSidebarOpen(false)} ref={openDashboard}>
            {
                remainingDashboard && remainingDashboard.map((dashboards) => {
                    const { name } = dashboards
                    return <Tooltip title={name} placement="right">
                        <div className='extendedSidebarTabs' onClick={() => { handleModelOpen(dashboards); setIsNewSidebarOpen(false) }}>
                            {
                                name.length > 23 ? name.slice(0, 23) + "..." : name
                            }
                        </div>
                    </Tooltip>
                })
            }
        </div>
    )
}
export default ExtendedSidebar