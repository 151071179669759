import React from "react";
import { Typography } from "@mui/material";
import BarDrillDown from "./charts/BarDrillDown";
import BarWithBackground from "./charts/BarWithBackground";
import BasicLineChart from "./charts/BasicLineChart";
import CascadeChart from "./charts/CascadeChart";
import AreaChart from "./charts/AreaChart";
import SimpleGuage from "./charts/SimpleGuage";
import DefaultBarChart from "./charts/DefaultBarChart";
import DefaultBubbleChart from "./charts/DefaultBubbleChart";
import DefaultGradientStackedChart from "./charts/DefaultGradientStackedChart";
import DefaultPieChart from "./charts/DefaultPieChart";
import BigNumberChart from "./charts/BigNumberChart";
import TreeChart from "./charts/TreeChart";
import BasicScatterChart from "./charts/BasicScatterChart";
import Heatmap from "./charts/Heatmap";
import TableView from "./charts/TableView"
import CardChart from "./charts/CardChart";
import GraphChart from "./charts/GraphChart";

function GetCharts(props) {

  switch (props.data.type) {
    case "BarDrillDown":
      return (
        <BarDrillDown
          filterChart={props.setFilter}
          // chartData={props.chartData}
          data={props.data}
          theme={props.theme}
          chartTheme={props.chartTheme}
        />
      );
    case "BarWithBack":
      return (
        <BarWithBackground
          filterChart={props.setFilter}
          // chartData={props.chartData}
          theme={props.theme}
          chartTheme={props.chartTheme}
          data={props.data}
        />
      );
    case "BasicLineChart":
      return (
        <BasicLineChart
          filterChart={props.setFilter}
          // chartData={props.chartData}
          theme={props.theme}
          chartTheme={props.chartTheme}
          data={props.data}
          editMode={props.editMode}
        />
      );
    case "CascadeChart":
      return (
        <CascadeChart
          filterChart={props.setFilter}
          theme={props.theme}
          chartTheme={props.chartTheme}
          // chartData={props.chartData}
          data={props.data}
        />
      );
    case "AreaChart":
      return (
        <AreaChart
          filterChart={props.setFilter}
          // chartData={props.chartData}
          theme={props.theme}
          chartTheme={props.chartTheme}
          data={props.data}
          editMode={props.editMode}
        />
      );
    case "SimpleGuage":
      return (
        <SimpleGuage
          // chartData={props.chartData}
          data={props.data}
          chartTheme={props.chartTheme}
        />
      );
    case "DefaultBarChart":
      return (
        <DefaultBarChart
          filterChart={props.setFilter}
          theme={props.theme}
          // chartData={props.chartData}
          data={props.data}
          chartTheme={props.chartTheme}
          editMode={props.editMode}
        />
      );
    case "DefaultBubbleChart":
      return (
        <DefaultBubbleChart
          filterChart={props.setFilter}
          theme={props.theme}
          // chartData={props.chartData}
          data={props.data}
          chartTheme={props.chartTheme}
          editMode={props.editMode}
        />
      );
    case "DefaultGradientStackedChart":
      return (
        <DefaultGradientStackedChart
          filterChart={props.setFilter}
          theme={props.theme}
          // chartData={props.chartData}
          data={props.data}
          chartTheme={props.chartTheme}
          editMode={props.editMode}
        />
      );
    case "DefaultPieChart":
      return (
        <DefaultPieChart
          filterChart={props.setFilter}
          theme={props.theme}
          // chartData={props.chartData}
          data={props.data}
          chartTheme={props.chartTheme}
          editMode={props.editMode}
        />
      );
    case "BigNumberChart":
      return (
        <BigNumberChart
          theme={props.theme}
          // chartData={props.chartData}
          data={props.data}
          chartTheme={props.chartTheme}
        />
      );
    case "BasicScatterChart":
      return (
        <BasicScatterChart
          filterChart={props.setFilter}
          theme={props.theme}
          // chartData={props.chartData}
          data={props.data}
          chartTheme={props.chartTheme}
          editMode={props.editMode}
        />
      );
    case "Heatmap":
      return (
        <Heatmap
          filterChart={props.setFilter}
          theme={props.theme}
          // chartData={props.chartData}
          data={props.data}
          chartTheme={props.chartTheme}
          editMode={props.editMode}
        />
      );
    case "TreeChart":
      return (
        <TreeChart
          filterChart={props.setFilter}
          theme={props.theme}
          // chartData={props.chartData}
          data={props.data}
          chartTheme={props.chartTheme}
          editMode={props.editMode}
          // collapse={props.collapse}
        />
      );
    case "TableView":
      return (
        <TableView
          filterChart={props.setFilter}
          theme={props.theme}
          data={props.data}
          chartTheme={props.chartTheme}
          editMode={props.editMode}
        />
      );
    case "CardChart":
      return (
        <CardChart
          filterChart={props.setFilter}
          theme={props.theme}
          data={props.data}
          chartTheme={props.chartTheme}
          editMode={props.editMode}
        />
      );
      case "GraphChart":
        return (
          <GraphChart
            filterChart={props.setFilter}
            theme={props.theme}
            data={props.data}
            chartTheme={props.chartTheme}
            editMode={props.editMode}
          />
        );
    case "NoData" : 
        return <Typography>No data was found based on the question that you have asked. Please try changing question!</Typography>
    default:
      return <Typography>Sorry we don't have that chart for now!</Typography>;
  }
}

export default GetCharts;
