import React, { useState, useRef, useEffect } from 'react'
import { MdOutlineDeleteOutline } from 'react-icons/md';
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";


function RemoveChart({ currentChartId, setData, data }) {
    const [isDeleteModeActive, setIsDeleteModeActive] = useState(false)
    const removeChartRef = useRef(null);
    const handleDelete = () => {
        const newChildElements = data.filter(element => element.i !== currentChartId)
        setData(newChildElements)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (removeChartRef.current && !removeChartRef.current.contains(event.target)) {
                setIsDeleteModeActive(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div style={{ zIndex: 90 }} ref={removeChartRef}>
            <Tooltip title="Chart Types" placement="right">
                <MdOutlineDeleteOutline style={{ fontSize: "20px", color: "rgba(58, 0, 128, 0.742)" }} onClick={() => setIsDeleteModeActive(true)} />
            </Tooltip>
            {
                isDeleteModeActive && <div className='confirmDeleteChart'>
                    <p>Are you sure?</p>
                    <div>
                        <Button
                            variant="contained"
                            className='addChartBodyContainerAddBtn'
                            style={{ marginRight: "5%", width: "100px" }}
                            onClick={() => handleDelete()}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            className='addChartBodyContainerAddBtn'
                            style={{ width: "100px" }}
                            onClick={() => setIsDeleteModeActive(false)}
                        >
                            No
                        </Button>
                    </div>
                </div>
            }

        </div>
    )
}

export default RemoveChart