import React from "react";
import "./styles.css";

const Loader = () => {
  return (
    <div className="loader">
      <div className="loader__spinner"></div>
    </div>
    // <span class="loader"></span>
  );
};

export default Loader;
