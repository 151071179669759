import EChartsReact from "echarts-for-react";
import {
    setBubbleChartOption,
    setOption,
    setStackAreaChartOption,
} from "../../services/ChartService";
import { generateDataForBarChart } from "../../services/DataService";
import * as echarts from "echarts";

export default function BasicScatterChart(props) {
    let chartModelColor = [];
    chartModelColor = props.chartTheme;
    var option = {};
    var chartType = "scatter";
    var title = "";
    var chartData = props.chartData;
    var prevValue = [];
    var series = [
        {
            symbolSize: 12,
            data: [
                0,
                0,
                0,
                132,
                0,
                158,
                1,
                1,
                1,
                213,
                2,
                2,
                2,
                18,
                3,
                3,
                3,
                86,
                3,
                232,
                4,
                4,
                4,
                65,
                5,
                5,
                5,
                86,
                6,
                6,
                6,
                213,
                7,
                7,
                7,
                219,
                8,
                8,
                8,
                29,
                9,
                9,
                9,
                67,
                9,
                145,
                10,
                10,
                10,
                89,
                11,
                11,
                11,
                203,
                12,
                12,
                12,
                192,
                13,
                13,
                13,
                239,
                14,
                2,
                14,
                14,
                15,
                15,
                15,
                187,
                16,
                16,
                16,
                140,
                17,
                17,
                17,
                79,
                18,
                18,
                18,
                20,
                19,
                17,
                19,
                19,
                20,
                20,
                20,
                23,
                21,
                21,
                21,
                76,
                21,
                189,
                22,
                22,
                22,
                242,
                23,
                23,
                23,
                248,
                24,
                17,
                24,
                24,
                25,
                25,
                25,
                239,
                26,
                26,
                26,
                98,
                27,
                27,
                27,
                202,
                28,
                28,
                28,
                71,
                28,
                208,
                29,
                29,
                29,
                63,
                30,
                30,
                31,
                31,
                31,
                232,
                32,
                32,
                32,
                212,
                33,
                28,
                33,
                33,
                34,
                34,
                34,
                210,
                35,
                35,
                35,
                167,
                36,
                36,
                36,
                216,
                37,
                37,
                37,
                116,
                38,
                38,
                38,
                47,
                39,
                39,
                39,
                166,
                40,
                32,
                40,
                40,
                41,
                41,
                41,
                147,
                42,
                42,
                43,
                43,
                43,
                115,
                44,
                44,
                44,
                70,
                45,
                45,
                45,
                179,
                46,
                46,
                46,
                212,
                47,
                47,
                47,
                143,
                48,
                36,
                48,
                48,
                49,
                49,
                49,
                143,
                50,
                17,
                50,
                50,
                51,
                47,
                51,
                51,
                52,
                52,
                52,
                133,
                53,
                53,
                53,
                164,
                54,
                9,
                54,
                54,
                55,
                55,
                55,
                66,
                56,
                56,
                56,
                204,
                57,
                27,
                57,
                57,
                58,
                45,
                58,
                58,
                59,
                59,
                59,
                85,
                60,
                2,
                60,
                60,
                61,
                61,
                61,
                168,
                62,
                62,
                62,
                144,
                63,
                63,
                63,
                245,
                64,
                49,
                64,
                64,
                65,
                65,
                65,
                157,
                66,
                17,
                66,
                66,
                67,
                67,
                67,
                186,
                68,
                68,
                68,
                226,
                69,
                17,
                69,
                69,
                70,
                70,
                70,
                219,
                71,
                36,
                71,
                71,
                72,
                72,
                72,
                98,
                73,
                73,
                73,
                143,
                74,
                74,
                74,
                95,
                75,
                75,
                75,
                82,
                76,
                76,
                76,
                238,
                77,
                75,
                77,
                77,
                78,
                78,
                78,
                172,
                79,
                79,
                79,
                214,
                80,
                80,
                81,
                81,
                82,
                82,
                83,
                83,
                84,
                84,
                85,
                51,
                85,
                85,
                86,
                28,
                86,
                86,
                86,
                104,
                87,
                88,
                87,
                143,
                88,
                88,
                88,
                155,
                89,
                70,
                89,
                89,
                90,
                90,
                90,
                126,
                91,
                91,
                91,
                193,
                92,
                92,
                92,
                143,
                93,
                93,
                93,
                204,
                94,
                94,
                94,
                139,
                95,
                95,
                95,
                184,
                96,
                96,
                96,
                198,
                97,
                15,
                97,
                97,
                98,
                98,
                98,
                201,
                99,
                98,
                99,
                99,
                99,
                162,
                100,
                86,
                100,
                100,
                101,
                101,
                101,
                202,
                102,
                102,
                102,
                153,
                103,
                95,
                103,
                103,
                104,
                77,
                104,
                104,
                105,
                105,
                105,
                233,
                106,
                106,
                106,
                210,
                107,
                96,
                107,
                107,
                108,
                108,
                108,
                229,
                109,
                16,
                109,
                109,
                110,
                110,
                110,
                163,
                111,
                111,
                111,
                140,
                112,
                112,
                112,
                208,
                113,
                17,
                113,
                113,
                114,
                114,
                114,
                117,
                115,
                115,
                115,
                172,
                116,
                22,
                116,
                116,
                117,
                43,
                117,
                117,
                118,
                46,
                118,
                118,
                118,
                152,
                119,
                95,
                119,
                119,
                120,
                120,
                120,
                178,
                121,
                121,
                121,
                172,
                122,
                122,
                122,
                217,
                123,
                123,
                123,
                185,
                124,
                12,
                124,
                124,
                125,
                125,
                125,
                152,
                126,
                25,
                126,
                126,
                127,
                84,
                127,
                127,
                128,
                128,
                128,
                235,
                129,
                129,
                129,
                212,
                130,
                130,
                130,
                132,
                131,
                3,
                131,
                86,
                131,
                131,
                132,
                132,
                132,
                229,
                133,
                77,
                133,
                133,
                134,
                134,
                134,
                163,
                134,
                206,
                135,
                135,
                135,
                177,
                136,
                136,
                136,
                188,
                137,
                137,
                137,
                230,
                138,
                138,
                138,
                140,
                139,
                18,
                139,
                139,
                140,
                42,
                140,
                140,
                141,
                117,
                141,
                141,
                142,
                142,
                142,
                247,
                143,
                58,
                143,
                143,
                144,
                144,
                144,
                167,
                145,
                145,
                145,
                223,
                146,
                128,
                146,
                146,
                147,
                147,
                147,
                148,
                148,
                148,
                148,
                199,
                149,
                145,
                149,
                149,
                150,
                150,
                150,
                188,
                151,
                83,
                151,
                151,
                152,
                152,
                152,
                249,
                153,
                57,
                153,
                153,
                154,
                154,
                154,
                194,
                155,
                118,
                155,
                155,
                156,
                38,
                156,
                156,
                157,
                152,
                157,
                157,
                158,
                158,
                158,
                197,
                159,
                43,
                159,
                159,
                160,
                160,
                160,
                172,
                161,
                117,
                161,
                161,
                162,
                162,
                162,
                173,
                163,
                163,
                163,
                188,
                164,
                65,
                164,
                164,
                164,
                226,
                165,
                128,
                165,
                165,
                166,
                166,
                166,
                188,
                167,
                159,
                167,
                167,
                168,
                163,
                168,
                168,
                169,
                54,
                169,
                169,
                170,
                170,
                170,
                218,
                171,
                171,
                171,
                235,
                172,
                13,
                172,
                157,
                172,
                172,
                173,
                173,
                173,
                205,
                174,
                66,
                174,
                174,
                175,
                175,
                175,
                177,
                176,
                121,
                176,
                176,
                177,
                102,
                177,
                177,
                178,
                66,
                178,
                178,
                179,
                163,
                179,
                179,
                180,
                180,
                180,
                192,
                181,
                97,
                181,
                181,
                182,
                107,
                182,
                182,
                183,
                183,
                183,
                215,
                184,
                184,
                184,
                249,
                185,
                179,
                185,
                185,
                186,
                78,
                186,
                186,
                187,
                49,
                187,
                187,
                188,
                163,
                188,
                188,
                189,
                189,
                189,
                204,
                190,
                190,
                190,
                220,
                191,
                117,
                191,
                191,
                192,
                192,
                192,
                218,
                193,
                162,
                193,
                193,
                194,
                194,
                194,
                204,
                195,
                195,
                195,
                247,
                196,
                123,
                196,
                196,
                197,
                40,
                197,
                197,
                198,
                179,
                198,
                198,
                199,
                154,
                199,
                199,
                200,
                200,
                201,
                201,
                202,
                202,
                203,
                203,
                204,
                204,
                205,
                5,
                205,
                205,
                206,
                123,
                206,
                206,
                207,
                144,
                207,
                207,
                208,
                208,
                209,
                209,
                210,
                210,
                211,
                211,
                212,
                212,
                213,
                213,
                214,
                214,
                215,
                215,
                216,
                216,
                217,
                217,
                218,
                140,
                218,
                218,
                219,
                93,
                219,
                219,
                220,
                114,
                220,
                220,
                221,
                98,
                221,
                221,
                222,
                157,
                222,
                222,
                223,
                16,
                223,
                223,
                224,
                36,
                224,
                224,
                225,
                225,
                225,
                244,
                226,
                14,
                226,
                226,
                227,
                102,
                227,
                227,
                228,
                145,
                228,
                228,
                229,
                48,
                229,
                229,
                230,
                195,
                230,
                230,
                231,
                119,
                231,
                231,
                232,
                151,
                232,
                232,
                233,
                147,
                233,
                233,
                234,
                29,
                234,
                234,
                235,
                28,
                235,
                235,
                236,
                64,
                236,
                236,
                237,
                48,
                237,
                237,
                238,
                114,
                238,
                238,
                239,
                96,
                239,
                239,
                240,
                89,
                240,
                240,
                241,
                104,
                241,
                241,
                242,
                64,
                242,
                242,
                243,
                57,
                243,
                243,
                244,
                15,
                244,
                244,
                245,
                119,
                245,
                245,
                246,
                46,
                246,
                246,
                247,
                109,
                247,
                247,
                248,
                26,
                248,
                248,
                249,
                75,
                249,
                249,
                250,
                0,
                250,
                58,
                251,
                1,
                251,
                36,
                252,
                2,
                252,
                91,
                253,
                3,
                253,
                17,
                254,
                4,
                254,
                38,
                255,
                5,
                255,
                50,
                256,
                6,
                256,
                23,
                257,
                7,
                257,
                27,
                258,
                4,
                258,
                8,
                259,
                9,
                259,
                66,
                260,
                10,
                260,
                100,
                261,
                11,
                261,
                22,
                262,
                12,
                262,
                54,
                263,
                13,
                263,
                116,
                264,
                14,
                264,
                20,
                265,
                15,
                265,
                42,
                266,
                16,
                266,
                31,
                267,
                17,
                267,
                22,
                268,
                18,
                268,
                75,
                269,
                19,
                269,
                58,
                270,
                20,
                270,
                108,
                271,
                21,
                271,
                37,
                272,
                22,
                272,
                88,
                273,
                23,
                273,
                44,
                274,
                24,
                274,
                239
            ].map((value, index) => {
                if (index % 2 === 0) {
                    prevValue = value;
                }
                else if (index % 2 === 1) {
                    let array = [prevValue, value]
                    return array;
                }
            }).filter(data => data !== undefined),
            type: 'scatter',
            itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: chartModelColor[1].mainColor,
                  },
                  {
                    offset: 1,
                    color: chartModelColor[0].gradientColor,
                  },
                ])
              }
        }
    ]
    option = {
        title: {
            text: title
        },
        tooltip:{
            show:true,
            trigger : 'item'
          } ,
        xAxis: {},
        yAxis: {},
        series: series
    }

    return (
        <>
            {props.editMode ? (
                <EChartsReact
                    option={option}
                    style={{ height: 600, width: 900 }}
                />
            ) : (
                <EChartsReact option={option} style={{ height: 600, width: 900 }}/>
            )}
        </>
    );
}
