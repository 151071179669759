import EChartsReact from "echarts-for-react";
import {
  setOption,
  setStackAreaChartOption,
} from "../../services/ChartService";
import { generateDataForBarChart } from "../../services/DataService";
import * as echarts from "echarts";

export default function AreaChart(props) {
  let chartModelColor = [];
  chartModelColor = props.chartTheme;
  var option = {};
  var chartType = "line";
  var title = "";
  var chartData = props.data.data ? props.data.data : [{}];
  var colorAreaStyle = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: chartModelColor[0].mainColor,
    },
    {
      offset: 1,
      color: chartModelColor[0].gradientColor,
    },
  ]);

  let yAxisData = {
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.25,
        color: props.theme === "light" ? "#4F4F7935" : "#9090BB35",
      },
    },
  };
  var titleColorTheme = props.theme === "light" ? "#4F4F79" : "#9090BB";
  var XaxisChartName = props.data.chartProps.xAxis;
  var YaxisChartName = props.data.chartProps.yAxis;
  const dataArray = chartData.map((item) => {
    return { name: item[XaxisChartName], value: item[YaxisChartName] };
  });

  const xAxisData = chartData.map((item) => {
    return item[XaxisChartName];
  });

  function initData() {
    var obj = generateDataForBarChart(chartData, XaxisChartName);

    option = setOption(
      titleColorTheme,
      xAxisData,
      yAxisData,
      chartType,
      dataArray,
      title,
      colorAreaStyle,
      "",
      "",
      "",
      chartData,
      XaxisChartName,
      YaxisChartName,
      obj.categories
    );
  }

  initData();
  function onChartClick(params, echarts) {
    var obj = { [XaxisChartName]: params.name };
    props.filterChart(obj);
  }

  return (
    <>
      {props.editMode ? (
        <EChartsReact
          option={option}
          onEvents={{
            click: onChartClick,
          }}
          style={{ height: "100%" }}
        />
      ) : (
        <EChartsReact option={option} style={{ height: "100%" }} />
      )}
    </>
  );
}
