import * as msal from '@azure/msal-browser';

 const msalConfig = {
    auth: {
      clientId: "002dcf67-c928-4b52-a3ef-05fb194c61d1",
      redirectUri: "http://localhost:3000",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
  };
  
  export const scopes = ["user.read"];
  
  export const homeAccountId = "homeAccountID";

  export const msalInstance = new msal.PublicClientApplication(msalConfig);