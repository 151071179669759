import testJson from '../util/TestData.json';
import * as XLSX from 'xlsx';
export function generateDataForDrillDOwn(data, key, key2) {
    var keyValArray = [];
    var keyArray = [];
    var valArray = [];
    var obj = {
      'categories': [],
      'dataArray': [],
      'drilldownData': []
    }
    var byType = groupByCategory1(data, key, key2);
    for (var type in byType) {
      keyArray.push(type);
      obj.dataArray.push(
        {
          value: byType[type].value,
          groupId: type
        }
      );
      keyValArray = conertJsonToKeyValArray(byType[type][key2]);
      obj.drilldownData.push(
        {
          dataGroupId: type,
          data: keyValArray
        }
      );
    }
    obj.categories = keyArray;
    
    // create a new workbook and add a worksheet
    // var wb = XLSX.utils.book_new();
    // var ws_data = XLSX.utils.json_to_sheet(data);
 
    // // add the worksheet to the workbook
    // XLSX.utils.book_append_sheet(wb, ws_data, "Data");
    
    // // add drilldown sheets to the workbook
    // obj.drilldownData.forEach(function(drilldown) {
    //   var ws_drilldown = XLSX.utils.json_to_sheet(drilldown.data);
    //   XLSX.utils.book_append_sheet(wb, ws_drilldown, drilldown.dataGroupId);
    // });
  
    // // save the workbook as an Excel file
    // XLSX.writeFile(wb, "data.xlsx");
  
    return obj;
  };
  
  

function conertJsonToKeyValArray(obj) {
    var keyValArray = [];
    Object.keys(obj).forEach(key => {
        keyValArray.push([key, obj[key]]);
    });
    return keyValArray;
}
function groupByCategory1(array, groupBy1, groupBy2) {
    var groups = {};

    array.forEach(function (element) {
        if (groups[element[groupBy1]]) {
            groups[element[groupBy1]]["value"] = groups[element[groupBy1]]["value"] + 1;

            groups[element[groupBy1]][groupBy2][element[groupBy2]] = groups[element[groupBy1]][groupBy2][element[groupBy2]] ? groups[element[groupBy1]][groupBy2][element[groupBy2]] + 1 : 1;

        } else {
            groups[element[groupBy1]] = { "value": 1 };
            groups[element[groupBy1]][groupBy2] = {};
            groups[element[groupBy1]][groupBy2][element[groupBy2]] = groups[element[groupBy1]][groupBy2][element[groupBy2]] ? groups[element[groupBy1]][groupBy2][element[groupBy2]] + 1 : 1;

        }
    });
    return groups;
}

export function generateDataForBarChart(data, key) {
    var keyArray = [];
    var valArray = [];
    var obj = {
        'categories': [],
        'dataArray': [],
    }
    var byType = groupByCategory(data, key);
    for (var type in byType) {
        //var displayName = getDisplayName(data, key, type);
        keyArray.push(type);
        valArray.push(byType[type]);
    }
    obj.categories = keyArray;
    obj.dataArray = valArray;
    return obj;

};
export function generateDataFromJSON(data) {
    var keyValArray = [];
    var obj = {
        'categories': [],
        'dataArray': [],
        'drilldownData': []
    }
    Object.keys(data).forEach(key => {
        keyValArray = [];
        Object.keys(data[key]).forEach(key1 => {
            var nestedObj = data[key][key1]
            Object.keys(nestedObj).forEach(key2 => {
                keyValArray.push([key2, nestedObj[key2]]);
            });

        });
        obj.categories.push(key);
        obj.dataArray.push(
            {
                value: data[key].length,
                groupId: key
            }
        );
        obj.drilldownData.push(
            {
                dataGroupId: key,
                data: keyValArray
            }
        );

    })
    return obj;
};

export function generateDataForPieChart(data, key) {

    var dataArray = [];
    var byType = groupByCategory(data, key);
    for (var type in byType) {
        var obj = {
            value: byType[type],
            name: type
        }
        dataArray.push(obj);
    }
    dataArray.sort(function (a, b) {
        return a.value - b.value;
    })
    return dataArray;
};
function groupByCategory(array, groupBy) {
    var groups = {};
    array.forEach(function (element) {
        if (groups[element[groupBy]]) {
            groups[element[groupBy]] = groups[element[groupBy]] + 1;
        } else {
            groups[element[groupBy]] = 1;
        }
    });
    return groups;

}
export function getSeriesData(processResourceCount, rowBody) {
    var objArray = [];
    for (var i = 0; i < rowBody.length; i++) {
        var count = 0;
        if (processResourceCount[i]) {
            count = processResourceCount[i].count
        }
        var obj = {
            value: count,
            groupId: rowBody[i].name
        }
        objArray.push(obj);

    }
    return objArray
}
export function getNameArray(obj) {
    var objArray = [];
    for (var i = 0; i < obj.length; i++) {

        objArray.push(obj[i].name);

    }
    return objArray
};
function generateRowColArray(array) {
    var rowColArray = {
        rowArr: [],
        colArr: []
    };
    for (var i = 0; i < array.length; i++) {
        if (i % 2 == 0) {
            rowColArray.rowArr.push(array[i]);
        } else {
            rowColArray.colArr.push(array[i]);
        }
    }
    return rowColArray;
}
export function groupByRow(array) {
    var groups = {};

    var rowColArray = {};
    rowColArray = generateRowColArray(array);
    groups = generateCountObjArray(rowColArray.rowArr, rowColArray.colArr);

    return groups;
}

function generateCountObjArray(rowVal, colVal) {
    var groups = {};
    for (var i = 0; i < rowVal.length; i++) {
        var element = rowVal[i];
        if (groups[element]) {
            var obj = groups[element];
            obj["count"] = obj.count + 1;
            obj["obj"].push([rowVal[i], colVal[i]]);
            groups[element] = obj;
        } else {
            var obj = { "count": 1, "obj": [[rowVal[i], colVal[i]]] }
            groups[element] = obj;
        }
    };
    return groups;
}

function generateRelationship(rowVal, colVal, rowNameArray,colNameArray) {
    var mapping = [];
    var groups = {};
    var obj = {};
    var rowIndex = 0;
    for (var i = 0; i < rowVal.length; i++) {
        obj = {};
        rowIndex = rowVal[i];
        if(mapping[rowIndex]){
            obj = mapping[rowIndex];
            var childObj = {"name":colNameArray[colVal[i]], "value":1};
            obj.children.push(childObj);
            mapping[rowIndex] = obj;
        }else{
            obj["name"] = rowNameArray[rowIndex];
            obj["children"] = [];
            var childObj = {"name":colNameArray[colVal[i]], "value":1};
            obj.children.push(childObj);
            mapping.push(obj); 
        }
    }
    return mapping;
};
export function getRowColMaping(jsonData) {
    var mapping = jsonData.results.graphs["responsible.resource"];
    var rowColArray = {};
    var rowBody = generateJsonFromBody(jsonData.results.rows.heading, jsonData.results.rows.body);
    var colBody = generateJsonFromBody(jsonData.results.columns.heading, jsonData.results.columns.body);
    var rowNameArray = getNameArray(rowBody);
    var colNameArray = getNameArray(colBody);
    var rowColArray = generateRowColArray(mapping);
    var relationshipObj = generateRelationship(rowColArray.rowArr, rowColArray.colArr, rowNameArray, colNameArray);
    return relationshipObj;
}
export function generate2DArray(array, dimention) {
    var rowColMapping = [];
    for (var i = 0; i < array.length; i += dimention) {
        var obj = [];
        if (i % dimention == 0) {
            obj.push(array[i]);
        }
        obj.push(array[i + 1])
        rowColMapping.push(obj)
    }
    return rowColMapping;
}

function getDisplayName(data, key, id) {
    var displayName = "";
    var headings = testJson.results.heading;
    for (var h = 0; h < headings.length; h++) {
        if (headings[h].name === key) {
            var members = headings[h].members;
            for (var i = 0; i < members.length; i++) {
                if (members[i].value == id) {
                    displayName = members[i].displayName;
                    break;
                }
            }
        }
    }
    return displayName;
}
export function generateJsonFromBody(headingsArray, bodyArray) {

    var headings = [];
    var bodyObjArray = [];
    Object.keys(headingsArray).forEach(h => {
        var obj = {};
        obj["key"] = headingsArray[h].name;
        // headings.push({"Key":data.results.heading[h].name});
        if (headingsArray[h].members) {
            obj["members"] = headingsArray[h].members;
        }
        headings.push(obj);
    });

    Object.keys(bodyArray).forEach(index => {
        var body = bodyArray[index];
        var bodyObj = {};
        Object.keys(body).forEach(index2 => {
            //var h1 = headings[index2];
            var val = body[index2];
            if (headings[index2].hasOwnProperty("members")) {
                var obj = headings[index2]["members"].filter((el) => el.value === val);
                val = obj.length > 0 ? obj[0].displayName : ""
            }
            bodyObj[headings[index2]["key"]] = val;


        });
        bodyObjArray.push(bodyObj);
    });
    return bodyObjArray;
};

export function groupByProcess(array) {

    var groups = {};
    array.forEach(function (element) {
        if (groups[element]) {
            groups[element] = groups[element] + 1;
        } else {
            groups[element] = 1;
        }
    });
    return groups;
}

export function generateJSON(data) {


    var bodyObjArray = [];
    bodyObjArray = generateJsonFromBody(data.results.heading, data.results.body);
    return bodyObjArray;
};
export function getColumnData(columnName, data) {
    var valArray = [];
    for (var key in data) {

        valArray.push(data[key][columnName]);

    }
    var unique = valArray.filter((item, i, ar) => ar.indexOf(item) === i);
    return unique;
};
export function filterChartData(columnName, value, data) {
    var filteredData = [];

    filteredData = data.filter((item, i, ar) => item[columnName] === value);
    // for (var key in data) {
    //     if(data[key][columnName] == value){
    //         filteredData.push();
    //     }
    //     valArray.push(data[key][columnName]);
    // }
    // var unique = valArray.filter((item, i, ar) => ar.indexOf(item) === i);
    return filteredData;
};

