export const chartOptions = [
  {
    name: "Line Chart",
    value: "BasicLineChart",
  },
  // {
  //   name: "Area Chart",
  //   value: "AreaChart",
  // },
  {
    name: "Bar Chart",
    value: "DefaultBarChart",
  },
  // {
  //   name: "Bubble Chart",
  //   value: "DefaultBubbleChart",
  // },
  {
    name: "Pie Chart",
    value: "DefaultPieChart",
  },
  {
    name: "Table",
    value: "TableView",
  },
  // {
  //   name: "Card",
  //   value: "CardChart",
  // },
  // {
  //   name: "Graph Chart",
  //   value: "GraphChart",
  // },
];
