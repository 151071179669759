import React, { useEffect, useState } from 'react'
import "./Header.css"
import { BiSearchAlt2 } from 'react-icons/bi';
import { FaUserCircle } from 'react-icons/fa';
import Logo from './Logo';
import { homeAccountId, msalInstance } from "../LoginPage/config"
import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('abcdefghijklmnop'); 

const decryptString = (encrypted) => {
  console.log("encrypted",encrypted.replace(/ /g, '+'))
  let str =encrypted.replace(/ /g, '+')
  let decryptedBytes = CryptoJS.AES.decrypt(str, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
  return decryptedBytes.toString(CryptoJS.enc.Utf8);
};
function Header({ dbName, setDbName, setIsUserLoggedIn, dbNames, setData, setLayouts }) {
    const urlParams = new URLSearchParams(window.location.search);
    const encrypted = urlParams.get('encrypted'); 
   
    const decryptedMessage = decryptString(decodeURIComponent(encrypted));
    const params = {};
    decryptedMessage.split('&').forEach(param => {
      const [key, value] = param.split('=');
      params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
     });
    const sitename = params['sitename']; 

    const [isUserLoggedOut, setIsUserLoggedOut] = useState(false)
    const [userInitials, setUserInitials] = useState(null)
    const handleLogout = () => {
        localStorage.clear(); // Clear local storage
        setIsUserLoggedIn(false); // Set user logged in state to false
        // Optionally, redirect user to another page after logout
        window.location.href = 'https://'+sitename+'/System_Information';
    };

    
    // const handleSelectChange = async (event) => {
    //     setDbName(event.target.value);
    //     const storedUser = localStorage.getItem("user")
    //     let userData = null
    //     if (storedUser) {
    //         userData = JSON.parse(storedUser)
    //     }
    //     const fetchUrl = "http://localhost:3001/dashboard/fetch";
    //     const response = await fetch(fetchUrl, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ userName: userData.username, dashName: event.target.value }),
    //     });
    //     const dashboardData = await response.json();
    //     if (dashboardData.status === "success") {
    //         setData(dashboardData.childElements)
    //         setLayouts(dashboardData.layouts)
    //         setDbName(dashboardData.dashName)
    //     }
    // };
    useEffect(() => {
        const storedUser = localStorage.getItem("user")
        let userData = null
        let name = null
        if (storedUser) {
            userData = JSON.parse(storedUser)
            name = userData.name
            //const splitName = name.split(" ")
            if (name && name.length > 0) {
                const firstInitial = name.charAt(0).toUpperCase();
                const splitName = name.split(" ");
                if (splitName.length >= 2) {
                  // Extract first initial of the second part
                  const secondNameInitial = splitName[1].charAt(0).toUpperCase();
                  setUserInitials(firstInitial+secondNameInitial);
                } 
                else{
                    setUserInitials(firstInitial)
                }
            
            }
        }
    }, [])
    return (
        <div className='headerContainer'>
            <div className='headerBodyContainer1'>
                <div className='headerBodyContainer1Logo'>
                    <Logo />
                </div>
                <div className='headerBodyContainer1Input'>
                    {/* <input value={dbName} onChange={(e) => setDbName(e.target.value)} placeholder='Enter Dashboard Name' /> */}
                    <div>{dbName}</div>
                    <span className='headerBodyContainer1InputHeading'></span>
                </div>
            </div>
            <div className='headerBodyContainer2'>
                <div className='headerBodyContainer2Input'>
                    {/* <BiSearchAlt2 className='headerBodyItem2Icon1' />
                    <input
                        type='text'
                        placeholder='Search'
                    /> */}
                </div>
                <div className='headerBodyContainer2User'>
                    <div className={isUserLoggedOut ? "headerBodyContainer2UserBody logout" : 'headerBodyContainer2UserBody'} onClick={() => { setIsUserLoggedOut(true); handleLogout() }}>
                        {
                            userInitials ? <div className='userInitials'>{userInitials}</div> :
                                <FaUserCircle style={{ fontSize: "30px" }} />
                        }
                    </div>
                </div>
            </div>

            {/* <div className='filterChartContainer headerBodyItem5'>
                <select value={dbName} onChange={handleSelectChange} >
                    {dbNames && dbNames.map((name, index) => (
                        <option key={index} value={dbNames.names}>
                            {name.names}
                        </option>
                    ))}
                </select>
            </div> */}

        </div>
    )
}

export default Header