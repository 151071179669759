import React, { memo, useCallback, useEffect, useRef, useState,  } from "react";
import "./styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import CssBaseline from "@mui/material/CssBaseline";
import { Paper, Grid, Container, Box, Input, Tooltip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { FiMinimize } from "react-icons/fi";
import Loader from "../Loader/Loader";
import SearchLoader from "../Loader/SearchLoader";
import { GrFormClose  } from "react-icons/gr";
import { FaTrash } from 'react-icons/fa';
import { AiFillSave, AiOutlinePlus } from "react-icons/ai";
import { BiCustomize } from "react-icons/bi";
import { BsPlusSquare, BsRobot } from "react-icons/bs";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";

import { v4 as uuidv4 } from 'uuid'


import {
  saveDashboard,
  getQuestions,
  getDashData,
  createDashboard,
  deleteDashboard,
  getQueryData,
  getChartHeading,
  getChartType,
  getDefaultAiQuestions,
  getDashboardName,
  createAiDashboard,
  generateUniqueQuestion,
  saveDashboardData,
  getRemainingDashboardNames,
  getNeoForeQueryData
} from "../../apiServices/dashboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddChart from "./AddChart";
import AddChartTabs from "./AddChartTabs";
import { defaultElement } from "../../defaultElements";
import { getId } from "../../util";
import GetCharts from "../GetCharts";
import Search from "./Search";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import RemoveChart from "./RemoveChart";
import FilterChart from "./FilterChart";
import AxisMenu from "./AxisMenu";
import Story from "./Story";

import useDashboardContext from "../../Contexts/DashboardContext";

const MemoizedGetCharts = memo(GetCharts);

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const mdTheme = createTheme();

function Dashboard({ setIsUserLoggedIn }) {



  const {userData, addUser} = useDashboardContext()
  const echartsRef = useRef(null);

  const [layouts, setLayouts] = useState({
    lg: [],
    md: [],
    sm: [],
    xs: [],
    xxs: [],
  });
  const [data, setData] = useState([]);
  const [theme, setTheme] = useState("light");
  const [isLoading, setIsLoading] = useState(false);
  const [isChartEnlarged, setIsChartEnlarged] = useState(false);
  const [currentChart, setCurrentChart] = useState(null);
  const [isAddChartOpened, setIsAddChartOpened] = useState(false);
  const [defaultQuestion, setDefaultQuestion] = useState(null);
  const [chartTheme, setChartTheme] = useState([
    { mainColor: "#6C69FF", gradientColor: "#3834FE" },
    { mainColor: "#2DE0D2", gradientColor: "#29CCBF" },
    { mainColor: "#EFDE41", gradientColor: "#FFFFFF" },
    { mainColor: "#29CCBF", gradientColor: "#2DE0D2" },
    { mainColor: "#F85F3E", gradientColor: "#FFFFFF" },
    { mainColor: "#3834FE", gradientColor: "#FFFFFF" },
    { mainColor: "#AE1AE1", gradientColor: "#FFFFFF" },
    { mainColor: "#85C236", gradientColor: "#FFFFFF" },
    { mainColor: "#670EAC", gradientColor: "#FFFFFF" },
  ]);
  const [editMode, setEditMode] = useState(true);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [currentChartId, setCurrentChartId] = useState("");
  const [dbName, setDbName] = useState("");
  const [dbNames, setDbNames] = useState([]);
  const [isOptionExpanded, setIsOptionExpanded] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState("");
  const [createNewDashboard, setCreateNewDashboard] = useState(false);
  const [newDashbardFilter, setNewDashbardFilter] = useState("all");
  const [remainingDashboard, setRemainingDashboard] = useState([]);
  const storedTabs = localStorage.getItem("dashboardTabs");
  const parsedTabs = JSON.parse(storedTabs);
  const hasRun = useRef(false);
  
  const storeduserData = localStorage.getItem("user");
  // const [isDashboardToggleFilterActive, setIsDashboardToggleFilterActive] = useState(false)
  const handleBreakpointChange = (breakpoint) => {};

  const handleLayoutChange = useCallback((layout, newLayouts) => {
    if (!hasRun.current) {
      console.log('Layout changed:', newLayouts);
      setLayouts(newLayouts);
      hasRun.current = true; // Set flag to true after first run
    }
    else{
   console.log('Layout changed:', newLayouts);
   setLayouts(newLayouts);
    }
  }, []);

 
  
  const resetUseStateStates = () => {
    setIsLoading(false);
    setIsChartEnlarged(false);
    setCurrentChart(null);
    setIsAddChartOpened(false);
    setDefaultQuestion(null);
    setIsSearchClicked(false);
    setCurrentChartId("");
    setIsOptionExpanded(false);
    setData([]);
  };
  console.log("newData::::::::::::::::::::::::::::::::::",data)
  const handleDashboardSave = async () => {
    // const storedUser = localStorage.getItem("user");
    // let userData = null;
    // if (storedUser) {
    //   userData = JSON.parse(storedUser);
    // }
  
    if (!dbName || dbName.length === 0) {
      toast.error("Dashboard name cannot be empty!!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close the toast after 3 seconds
      });
      return;
    } else if (!userData.username) {
      toast.error("Username cannot be empty!!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // Close the toast after 3 seconds
      });
      return;
    }

    try {
      console.log("layout",layouts)
      const dashboardSaveRes = await saveDashboard(
        userData,
        dbName,
        data,
        layouts
      );

      // if (dashboardSaveRes.status === "success") {
      toast.success(dashboardSaveRes.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close the toast after 3 seconds
      });
      // } else {
      //   toast.error(dashboardSaveRes.msg, {
      //     position: toast.POSITION.TOP_CENTER,
      //     autoClose: 2000, // Close the toast after 3 seconds
      //   });
      // }
    } catch (error) {
      console.error("Error saving dashboard:", error);
    }
  };

  const getDefaultQuestions = async () => {
    try {
      const defaultQuestionRes = await getQuestions();
      setDefaultQuestion(defaultQuestionRes);
    } catch (error) {
      console.error("Error fetching default questions:", error);
    }
  };
  const getDashboardData =  async(current_dashboard) => {
   
    console.log("userdataafter",userData);
    const dashboardData = await getDashData(userData, current_dashboard);
    console.log('dashboardData',dashboardData)
    if(dashboardData){
    setData(JSON.parse(dashboardData?.childElements));
    
    // var MapData =JSON.parse(dashboardData?.childElements)
    // console.log("mapdata",MapData)
    // MapData.map(async(item, index) => {
    //   console.log("id",item.i);
    //   console.log("id",item.question);
    //   await handleSearch(item.question,item.i)
    // })
    setLayouts(JSON.parse(dashboardData.layouts));
    setDbName(dashboardData.name);
    }
  };
  const getRefreshDashboardData =  async() => {

     setIsLoading(true);
     const toastId = toast.loading("Dashboard refresh in progress...", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false  // Keep the toast open until manually closed
    });
     try{
    var MapData = data;
    var newData = data;
    console.log("mapdata",data)
    for (const item of MapData) {
      console.log("id", item.i);
      console.log("question", item.question);
      console.log("deptid", item.deptid);

      // Await handleSearch for each item
      var updateData =await handleSearch(item.question, item.i, item.deptid,newData);
      console.log("chartIdreturn",updateData)
      newData = updateData;
    }
    setData(newData);
    toast.dismiss(toastId); 
  
  }
  catch(error)
  { 
    toast.update(toastId, {
    render: "Dashboard refresh failed. Please try again.",
    type: "error",
    autoClose: true, // Auto close after 5 seconds
    position: toast.POSITION.TOP_CENTER
  });
  toast.dismiss(toastId); 
  }
  finally {
    setIsLoading(false);
  }
  
    try {
      const dashboardSaveRes = await saveDashboard(
        userData,
        dbName,
        newData,
        layouts
      );
      toast.success(dashboardSaveRes.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close the toast after 3 seconds
      });
     
    } catch (error) {
      console.error("Error saving dashboard:", error);
    }
  }
   
  const handleSearch = async (newQuestion,currentChartId,deptid,MapData) => {
    //setIsLoading(true);
    console.log("newQuestion:::::25:::::::::::::::::::::",MapData)
    const queryDataRes = await getNeoForeQueryData(newQuestion,deptid);
    if(queryDataRes ==null || queryDataRes.length === 0){
      return MapData;
    }
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    
    // Example usage
    var newdate = new Date();
    var lastUpdated = formatDate(newdate);
    const newData = MapData.map((item) => {
      const { i } = item;
      if (i === currentChartId) {
       
        return {
          ...item,
          data: queryDataRes,
          question: newQuestion,
          lastUpdated:lastUpdated
        };
      } else return item;
    });
    console.log("chartId",newData)
    return newData;
   //setData(newData);
   //setIsLoading(false);
   // setIsSearchClicked(false);
  };
  const getRemainingNames = async (userData, existingTabs) => {
    const getRemainingTabsUrlRes = await getRemainingDashboardNames(userData);
    const remainingTabs = getRemainingTabsUrlRes.filter(tab => {
      return !existingTabs.some(existingTab => existingTab.id === tab.id);
    });
    console.log("remainingTabs::::::::::::::::::::",userData);
    localStorage.setItem("dashboardTabs",JSON.stringify(remainingTabs));
    console.log("dashboardTabs",JSON.stringify(remainingTabs));
    setRemainingDashboard(remainingTabs);
  };
 const isObjectEmpty = (obj) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };
  useEffect(()=>{let currentUser = null;
    if (storeduserData) {
      const parseduserData = JSON.parse(storeduserData);
      currentUser = parseduserData;
      addUser(parseduserData);
    }
   
    if (userData && !isObjectEmpty(userData)) {
      console.log("getremaining",userData)
    getRemainingNames(userData,[]);
    }

  },[userData.name])

  const handleLocalStorageState = () => {

    console.log("pparsedTabs:::::::::::::::::::::::",parsedTabs)
    if (parsedTabs && parsedTabs?.length != 0) {
      console.log("tabnotfound",storedTabs);
      console.log("parsedTabs:::::::::::::::::::::::",parsedTabs)
      if (parsedTabs.length > 0) {
        let activeTab = [];
         activeTab = parsedTabs.filter((tabs) => tabs.isActive === true);
        
        console.log("activeTab:s::::::::::::::::::::::::::",activeTab)
        if (activeTab.length === 0){
         setDbNames(parsedTabs);
         setDbName(parsedTabs[0].name);
         getDashboardData(parsedTabs[0]);
        }
        else{
          setDbNames(parsedTabs);
          setDbName(activeTab[0].name);
          getDashboardData(activeTab[0]);
        }
      } else {
        setCreateNewDashboard(true);
      }
      console.log("remainingDashboard",remainingDashboard);
      getRemainingNames(userData, parsedTabs);
      
     }
     // else {
    //   console.log("tabnotfound",parsedTabs);
    //   setCreateNewDashboard(true);
     // getRemainingNames(userData,[]);
    //   // getRemainingNames([], userData);
    // }
    // get user data
  }

  useEffect(() => {
    handleLocalStorageState();
 
    // getDefaultQuestions()
  }, [userData.name,parsedTabs]);

  const handleSelectChange = async (name) => {
    const currentDashboardId = name.id;
    // const storeduserData = localStorage.getItem("user");
    // let currentUser = null;
    // if (storeduserData) {
    //   const parseduserData = JSON.parse(storeduserData);
    //   currentUser = parseduserData;
    // }
    // const activeUser = user ? user.username : currentUser.username;
    console.log("DBnames",dbNames);
    const newDbNames = dbNames.map((names) => {
      if (names.id === currentDashboardId) {
        return {
          ...names,
          isActive: true,
        };
      } else {
        return {
          ...names,
          isActive: false,
        };
      }
    });
    setDbNames(newDbNames);
    localStorage.setItem("dashboardTabs",JSON.stringify(newDbNames));
    getDashboardData(name);
    setCreateNewDashboard(false);
    // if (!defaultQuestion)
    //   getDefaultQuestions()
    // else if (defaultQuestion && defaultQuestion.length === 0)
    //   getDefaultQuestions()
  };

  const handleNewDashboard = async (newName) => {
    if (!newDashboardName || newDashboardName.length === 0) {
      toast.error("Dashboard name cannot be empty!!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000, // Close the toast after 3 seconds
      });
      return;
    } else if (!userData.username) {
      toast.error("Username cannot be empty!!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000, // Close the toast after 3 seconds
      });
      return; 
    }

    const dashboard_id =uuidv4().replace(/-/g, '')

    const dashboardSaveRes = await createDashboard(userData, newDashboardName, dashboard_id);

    if (dashboardSaveRes.msg) {
      toast.success(dashboardSaveRes.msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000, // Close the toast after 3 seconds
      });
      setLayouts({
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: [],
      });
      resetUseStateStates();
      // getDefaultQuestions()
      const newDbNames = [
        ...dbNames.map((item) => ({ ...item, isActive: false })),
        { name: newDashboardName, id: dashboard_id, isActive: true },
      ];
      setDbNames(newDbNames);
      setDbName(newDashboardName);
      localStorage.setItem("dashboardTabs", JSON.stringify(newDbNames));
      setCreateNewDashboard(false);
      setNewDashboardName("");
      window.location.reload();
    }
    else {
      toast.warn(dashboardSaveRes.error, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500, // Close the toast after 2 seconds
      });
      setNewDashboardName("");
    }
    setNewDashbardFilter("all");
  };

  const handleDashboardDelete = async (dashboardID) => {
    let confirmDelete = await new Promise((resolve) => {
      toast(
        <div className='deleteConfirmationContainer'>
          <h5>Are you sure you want to delete this tab?</h5>
          <div>
            <Button
              variant='contained'
              className='addChartBodyContainerAddBtn'
              onClick={() => resolve(true)}
              style={{ marginRight: "5%", width: "100px" }}
            >
              Yes
            </Button>
            <Button
              variant='contained'
              className='addChartBodyContainerAddBtn'
              onClick={() => resolve(false)}
              style={{ width: "100px" }}
            >
              No
            </Button>
          </div>
        </div>
      );
    });
    if (confirmDelete) {
      console.log("Delete",confirmDelete)
      const isDeletedRes = await deleteDashboard(dashboardID);
      console.log("Delete",isDeletedRes.status)
      if (isDeletedRes.status === "success") {
        toast.success(isDeletedRes.msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000, // Close the toast after 3 seconds
        });
        const newTabList = dbNames.filter(
          (element) => element.id !== dashboardID
        );
        // let activeUser;
        setDbName("");
        if (newTabList.length > 0) {
          newTabList[newTabList.length - 1].isActive = true;
          setDbName(newTabList[newTabList.length - 1].name);
          getDashboardData(newTabList[newTabList.length - 1]);
          getRemainingNames(userData, newTabList);
          // getRemainingNames(newTabList, userData);
        } else {
          setLayouts({
            lg: [],
            md: [],
            sm: [],
            xs: [],
            xxs: [],
          });
          resetUseStateStates();
          setCreateNewDashboard(true);
          setNewDashboardName("");
          getRemainingNames(userData, []);
          // getRemainingNames([], user.username);
        }
        setDbNames(newTabList);
        localStorage.setItem("dashboardTabs", JSON.stringify(newTabList));
       
      } else {
        toast.error(isDeletedRes.msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000, // Close the toast after 3 seconds
        });
      }
    }
  };

  const handleTabClose = async (dashboardId) => {
    let confirmCloseTab = await new Promise((resolve) => {
      toast(
        <div className='deleteConfirmationContainer'>
          <h5>Are your sure?</h5>
          <div>
            <Button
              variant='contained'
              className='addChartBodyContainerAddBtn'
              onClick={() => resolve(true)}
              style={{ marginRight: "5%", width: "100px" }}
            >
              Yes
            </Button>
            <Button
              variant='contained'
              className='addChartBodyContainerAddBtn'
              onClick={() => resolve(false)}
              style={{ width: "100px" }}
            >
              No
            </Button>
          </div>
        </div>
      );
    });

    if (confirmCloseTab) {
      const newTabList = dbNames.filter(
        (element) => element.id !== dashboardId
      );
      // let activeUser;
      setDbName("");
      if (newTabList.length > 0) {
        newTabList[newTabList.length - 1].isActive = true;
       // setDbName(newTabList[newTabList.length - 1].name);
        getDashboardData(newTabList[newTabList.length - 1]);
       // getRemainingNames(userData, newTabList);
        // getRemainingNames(newTabList, userData);
      } else {
        setLayouts({
          lg: [],
          md: [],
          sm: [],
          xs: [],
          xxs: [],
        });
        resetUseStateStates();
        setCreateNewDashboard(true);
        setNewDashboardName("");
       // getRemainingNames(userData, []);
        // getRemainingNames([], user.username);
      }
      setDbNames(newTabList);
    //  localStorage.setItem("dashboardTabs", JSON.stringify(newTabList));
    }
  };

  const handleModelOpen = async (model) => {
    const newDbNames = [
      ...dbNames.map((item) => ({ ...item, isActive: false })),
      { name: model.name, id: model.id, isActive: true },
    ];
    setDbNames(newDbNames);
    setDbName(newDashboardName);
    localStorage.setItem("dashboardTabs", JSON.stringify(newDbNames));
    getDashboardData(model);
    const newRemainingTabs = remainingDashboard.filter(
      (tabs) => tabs.id !== model.id
    );

    setRemainingDashboard(newRemainingTabs);
    setCreateNewDashboard(false);
  };

  const makeApiCall = async (question, item) => {
    toast.success("generating query and fetching it's response", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000, // Close the toast after 3 seconds
    });
    const queryDataRes = await getQueryData(question);

    if (queryDataRes.status === "Data Found") {
      let queryData = queryDataRes.queryData;
      toast.success("generating title for chart", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // Close the toast after 3 seconds
      });
      const getChartHeadingRes = await getChartHeading(question);

      let splitValue,
        otherKeys,
        countKey,
        dataKeys,
        chartProps,
        chartTypeResJson;
      dataKeys = Object.keys(queryData[0]);
      countKey = dataKeys.find((key) => {
        splitValue = key.split(".");
        if (splitValue && splitValue[1].toLowerCase() === "count") {
          return key;
        }
      });
      otherKeys = dataKeys.filter((key) => {
        splitValue = key.split(".");
        if (splitValue && splitValue[1].toLowerCase() !== "count") {
          return key;
        }
      });

      // const modifingYaxis = otherKeys.map((keys) => {
      //   const splitedKeys = keys.split(".")
      //   return {
      //     displayName: splitedKeys[1],
      //     ogName: keys
      //   }
      // })
      chartProps = {
        xAxis: otherKeys[0],
        yAxis: countKey,
      };
      toast.success("Filtering chart type", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // Close the toast after 3 seconds
      });

      const chartTyperesData = await getChartType(queryData, question);
      chartTypeResJson = chartTyperesData;

      const title = chartTypeResJson.chartName;
      return {
        chartType: title,
        chartData: queryDataRes,
        chartHeading: getChartHeadingRes.heading,
        chartProps: chartProps,
        question: question,
      };
    } else {
      toast.error("Re-generating question for chart", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // Close the toast after 3 seconds
      });
      return {
        chartType: "",
        chartData: queryDataRes,
        chartHeading: "",
        chartProps: "",
        question: "",
      };
    }
  };

  const generateChartTemplate = (question) => {
    let title = "DefaultPieChart";
    let chartProps = {
      xAxis: "type",
    };

    let id = getId();
    let layout = defaultElement[title].layout;
    let item;
    item = {
      i: id,
      title: "New Chart",
      type: title,
      value: defaultElement[title].defaultValue,
      layout,
      chartProps,
      data: null,
      question: question,
      story: null,
    };
    const newLayouts = { ...item.layout, i: item.i };
    if (layouts.lg.some((item) => item.x === 0 && item.y === 0)) {
      layouts.md
        .map((item) => {
          if (item.x === 0) {
            return { y: item.y++, ...item };
          }
        })
        .concat(newLayouts);
      setLayouts({
        ...layouts,
      });
    } else {
      setLayouts({ lg: layouts.lg.concat([newLayouts]) });
    }
    return item;
  };

  const generateNewQuestion = async (questions) => {
    const uniqueQuestionUrlRes = await generateUniqueQuestion(questions);
    return uniqueQuestionUrlRes;
  };

  const GenerateStory = async () => {
    setNewDashbardFilter("loadQuestion");
    toast.success("GENERATING QUESTIONS PLEASE HOLD ON", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000, // Close the toast after 3 seconds
    });
    const defaultQuestionRes = await getDefaultAiQuestions(5);

    // handle no default question generation
    setNewDashbardFilter("generateDashboardName");
    toast.success("GENERATING DASHBOARD NAME PLEASE HOLD ON", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000, // Close the toast after 3 seconds
    });
    setDefaultQuestion(defaultQuestionRes);

    const questions = defaultQuestionRes;
    const getDasboardNameApiRes = await getDashboardName(
      questions,
      remainingDashboard,
      dbNames
    );
    // handle dashboard name here
    toast.success("GENERATING CHARTS PLEASE HOLD ON", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000, // Close the toast after 3 seconds
    });

    let aiDashboardName = getDasboardNameApiRes.dashboardName;
    if (!aiDashboardName || aiDashboardName.length === 0) {
      toast.error("Dashboard name cannot be empty!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000, // Close the toast after 3 seconds
      });
      return;
    } else if (!userData.username) {
      toast.error("Username cannot be empty!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000, // Close the toast after 3 seconds
      });
      return;
    }

    const dashboardSaveRes = await createDashboard(
      userData.username,
      aiDashboardName
    );
    if (dashboardSaveRes.status === "success") {
      setLayouts({
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: [],
      });
      resetUseStateStates();
      // getDefaultQuestions()
      const newDbNames = [
        ...dbNames.map((item) => ({ ...item, isActive: false })),
        { name: aiDashboardName, id: dashboardSaveRes.id, isActive: true },
      ];
      setDbNames(newDbNames);
      setDbName(aiDashboardName);
      localStorage.setItem("dashboardTabs", JSON.stringify(newDbNames));
      setCreateNewDashboard(false);
      setNewDashboardName("");
    } else {
      toast.error(dashboardSaveRes.msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000, // Close the toast after 3 seconds
      });
      setNewDashboardName("");
    }
    setNewDashbardFilter("all");
    let currentData = [];

    let newLayout = {
      lg: [],
      md: [],
      sm: [],
      xs: [],
      xxs: [],
    };

    let defaultLayout = { x: 0, y: 0, w: 5, h: 2 };

    //  This code is being used for creating the default template of charts as we hit the generate dashboard(Ai) button
    for (const que of questions) {
      if (currentData.length % 2 === 0) {
        if (defaultLayout.x === 5) {
          defaultLayout.y += 2;
        }
        defaultLayout.x = 0;
      } else {

        defaultLayout.x = 5;
      }

      const result = await generateChartTemplate(que.question);

      newLayout.lg.push({ ...defaultLayout, i: result.i });
      newLayout.md.push({ ...defaultLayout, i: result.i });

      currentData.push(result);
    }
    setData(currentData);
    setLayouts(newLayout);
    let existingQuestions = questions;
    for (const item of currentData) {
      let res = await makeApiCall(item.question, item);
      if (res) {
        let chartData = res.chartData;
        while (chartData.status === "No Data") {
          let newQuestion = await generateNewQuestion(existingQuestions);

          while (newQuestion[0].status === "No Question") {
            newQuestion = await generateNewQuestion(existingQuestions);
          }

          res = await makeApiCall(newQuestion[0].question, item);
          chartData = res.chartData;
          // if(chartData.status === "No Data"){
          existingQuestions.push({ question: newQuestion[0].question });
          // }
        }

        toast.success("generating Story", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Close the toast after 3 seconds
        });
        //need one update for story
        const storyUrl = "http://localhost:3001/openai/generate/story";
        const storyRes = await fetch(storyUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: chartData.queryData,
            question: res.question,
          }),
        });

        const storyData = await storyRes.json();
        const newData = currentData.map((dataItem) => {
          const { i } = dataItem;
          if (i === item.i) {
            return {
              ...dataItem,
              data: chartData.queryData,
              title: res.chartHeading,
              type: res.chartType,
              chartProps: res.chartProps,
              question: res.question,
              story: storyData.story,
            };
          } else return dataItem;
        });
        currentData = newData;
        setData(currentData);
      }
    }
   
    const SaveresponseRes = await saveDashboardData(
      userData.username,
      aiDashboardName,
      currentData,
      newLayout
    );
    SaveresponseRes.status === "success"
      ? toast.success("DASHBOARD CREATED SUCESSFULLY", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Close the toast after 3 seconds
        })
      : toast.error(dashboardSaveRes.msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Close the toast after 3 seconds
        });
        
  };

  return (
    <div>
      {currentChart ? (
        <Box className='enlarge'>
          <div style={{ height: "100%", width: "100%" }}>
            <div
              className='filterOptionsFullScreen'
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div></div>
              <div className='dashboardFullScreenTitle'>
                {currentChart.title}
              </div>
              <div
                style={{
                  cursor: "pointer",
                  margin: "1%",
                }}
                onClick={() => {
                  setCurrentChart(null);
                }}
              >
                <FiMinimize
                  style={{ fontSize: "25px" }}
                  onClick={() => {
                    setCurrentChart(null);
                  }}
                />
              </div>
            </div>
            <div style={{ height: "80%", width: "100%" }}>
              <MemoizedGetCharts
                data={currentChart}
                index={0}
                theme={theme}
                chartTheme={chartTheme}
                editMode={editMode}
              />
            </div>
          </div>
        </Box>
      ) : (
        ""
      )}
      {isAddChartOpened ? (
        <AddChart
          setIsAddChartOpened={setIsAddChartOpened}
          data={data}
          setData={setData}
          layouts={layouts}
          setLayouts={setLayouts}
          isAddChartOpened={isAddChartOpened}
          defaultQuestion={defaultQuestion}
          setIsOptionExpanded={setIsOptionExpanded}
        />
      ) : null}
      <Header
        dbName={dbName}
        setDbName={setDbName}
        setIsUserLoggedIn={setIsUserLoggedIn}
        dbNames={dbNames}
        setData={setData}
        setLayouts={setLayouts}
      />
      <div className='dashboardBodyContainer'>
        <Sidebar
          handleDashboardSave={handleDashboardSave}
          remainingDashboard={remainingDashboard}
          handleModelOpen={handleModelOpen}
        />
        <Box sx={{ display: "flex" }} className='dashboardContentContainer'>
          <CssBaseline />
          <Box
            sx={{
              flexGrow: 1,
              height: "100vh",
              width: "100%",
              overflow: "auto",
              backgroundColor: theme === "light" ? "#F6F9FB" : "#131322",
            }}
          >
            <div className='dashboardTabs'>
              {dbNames.map((name, index) => (
                <div
                  key={index}
                  className={
                    dbName === name.name
                      ? "dashboardTabsTab activeTab"
                      : "dashboardTabsTab inactiveTab"
                  }
                >
                  {
                    <Tooltip title={name.name} placement='bottom'>
                      <div onClick={() => handleSelectChange(name)}>
                        {name.name.length > 15
                          ? name.name.slice(0, 20) + "..."
                          : name.name}
                      </div>
                    </Tooltip>
                  }
                  <Tooltip title='TabClose' placement='top'>
                    <GrFormClose
                      color={dbName === name.name ? "white" : "black"}
                      fontSize={16}
                      style={{ cursor: "pointer", zIndex: "60" }}
                      onClick={() => handleTabClose(name.id)}
                    />
                  </Tooltip>
                  <br/>
                  <Tooltip title='Delete' placement='top'>
                    <FaTrash
                      color={dbName === name.name ? "white" : "black"}
                      fontSize={16}
                      style={{ cursor: "pointer", zIndex: "60" }}
                      onClick={() => handleDashboardDelete(name.id,name.name)}
                    />
                  </Tooltip>
                </div>
              ))}
              <div
                className='dashboardTabsTab addiconTab'
                onClick={() => {
                  setCreateNewDashboard(!createNewDashboard);
                  setNewDashbardFilter("all");
                }}
              >
                <AiOutlinePlus className='addnewdbicon' />
              </div>
            </div>
            {createNewDashboard ? (
              <div className='defaultDashboardContainer'>
                <>
                  {newDashbardFilter === "all" ? (
                    <div className='defaultDashboardOptionContainer'>
                      <div className='newDashboardHeading'>
                        CREATE NEW DASHBOARD
                      </div>
                      <Button
                        variant='contained'
                        className='addChartBodyContainerAddBtnCreate'
                        onClick={() => setNewDashbardFilter("custom")}
                      >
                        <div className='newDashboardIconDiv'>
                          <BiCustomize
                            fontSize={30}
                            className='newDashboardIcons'
                          />
                        </div>
                        <div className='newDashboardLabel'>
                          Custom Dashboard
                        </div>
                      </Button>
                      <br></br>
                      <Button
                        variant='contained'
                        className='addChartBodyContainerAddBtnCreate'
                        onClick={() => GenerateStory()}
                      >
                        <div className='newDashboardIconDiv'>
                          <BsRobot
                            fontSize={30}
                            className='newDashboardIcons'
                          />
                        </div>

                        <div className='newDashboardLabel'>
                          Generate Dashboard
                        </div>
                      </Button>
                    </div>
                  ) : null}
                  {newDashbardFilter === "loadQuestion" ? (
                    <div className='defaultDashboardInputContainer'>
                      <Loader /> 
                    </div>
                  ) : null}
                  {newDashbardFilter === "generateDashboardName" ? (
                    <div className='defaultDashboardInputContainer'>
                      <Loader />
                    </div>
                  ) : null}
                  {newDashbardFilter === "custom" ? (
                    <div className='defaultDashboardInputContainer'>
                      <Button
                        onClick={() => setNewDashbardFilter("all")}
                        variant='outlined'
                        sx={{
                          width: "25px !important",
                          position: "absolute",
                          top: "20px",
                        }}
                      >
                        <ArrowBackIcon />
                      </Button>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <input
                          type='text'
                          placeholder='Enter dashboard name'
                          value={newDashboardName}
                          onChange={(e) => setNewDashboardName(e.target.value)}
                        />
                        <Button
                          variant='contained'
                          className='addChartBodyContainerAddBtn'
                          // onClick = {()=>setCreateStory(true)}
                          onClick={() => handleNewDashboard()}
                        >
                          Create
                        </Button>
                      </Box>
                    </div>
                  ) : null}
                </>
              </div>
            ) : (
              <Container
                sx={{
                  mt: 0,
                  mb: 4,
                  paddingTop: "2%",
                  height: "100%",
                  width: "100%",
                  backgroundColor: theme === "light" ? "#F6F9FB" : "#131322",
                }}
                className={`${theme}`}
              >
                <Grid
                  style={{
                    backgroundColor: theme === "light" ? "#F6F9FB" : "#131322",
                  }}
                  container
                  spacing={1}
                  className={`${theme}`}
                >
                  <Grid
                    style={{
                      backgroundColor:
                        theme === "light" ? "#F6F9FB" : "#131322",
                    }}
                    item
                    xs={12}
                    md={12}
                    lg={12}
                  >
                    <Paper
                      id='chart-container'
                      ref={echartsRef}
                      elevation={0}
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%",
                        backgroundColor:
                          theme === "light" ? "#F6F9FB" : "#131322",
                      }}
                    >
                      <ResponsiveReactGridLayout
                        layouts={layouts}
                        onBreakpointChange={handleBreakpointChange}
                        onLayoutChange={handleLayoutChange}
                        breakpoints={{
                          lg: 1200,
                          md: 996,
                          sm: 768,
                          xs: 480,
                          xxs: 0,
                        }}
                        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                        margin={[10, 10]}
                        isDraggable={editMode ? true : false}
                        isResizable={editMode ? true : false}
                        className={`${theme}`}
                        style={{ backgroundColor: "inherit" }}
                      >
                        {data && 
                          data.map((item, index) => {
                            return (
                              <Box
                                key={item.i}
                                data-grid={item.layout}
                                style={{
                                  border:
                                    theme === "light"
                                      ? "1px solid #E1F0FF"
                                      : "1px solid #373565",
                                  overflow:
                                    item.type === "TreeChart" ||
                                    item.type === "Heatmap"
                                      ? "scroll"
                                      : null,
                                }}
                                className={
                                  isChartEnlarged && item.i === currentChartId
                                    ? "enlarge"
                                    : "backtoNormal"
                                }
                              >
                                <div style={{ height: "100%", width: "100%" }}>
                                  {isSearchClicked &&
                                  currentChartId === item.i ? (
                                    <div className='filterOptions'>
                                      <Search
                                        item={item}
                                        setIsLoading={setIsLoading}
                                        setData={setData}
                                        data={data}
                                        setCurrentChartId={setCurrentChartId}
                                        currentChartId={currentChartId}
                                        setIsSearchClicked={setIsSearchClicked}
                                      />
                                    </div>
                                  ) : (
                                    <div className='filterOptions'>
                                      <div className='chartTitle'>
                                        {item.title}
                                      </div>                                      
                                      {item.story ? (
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            marginRight: "1%",
                                          }}
                                        >
                                          <Story
                                            story={item.story && item.story}
                                            heading={item.title}
                                          />
                                        </div>
                                      ) : null}
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "1%",
                                        }}
                                      >
                                        <SearchRoundedIcon
                                          style={{
                                            fontSize: "20px",
                                            color: "rgba(58, 0, 128, 0.742)",                                            
                                          }}
                                          onClick={() => {
                                            setCurrentChartId(item.i);
                                            setIsSearchClicked(true);
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "1%",
                                        }}
                                        onClick={() => {
                                          setCurrentChart(item);
                                        }}
                                      >
                                        <FitScreenIcon
                                          style={{
                                            fontSize: "20px",
                                            color: "rgba(58, 0, 128, 0.742)",
                                          }}
                                          onClick={() => {
                                            setCurrentChart(item);
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "1%",                                          
                                        }}                                 
                                      >
                                        <RemoveChart
                                          currentChartId={item.i}
                                          setData={setData}
                                          data={data}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "1%",
                                        }}
                                      >
                                        <FilterChart
                                          currentChartId={item.i}
                                          setData={setData}
                                          data={data}
                                          setIsLoading={setIsLoading}
                                          type={item.type}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <AxisMenu
                                          currentChartId={item.i}
                                          setData={setData}
                                          data={data}
                                          currentData={item}
                                        />
                                      </div>
                                    </div>
                                  )}
                                 
                                  <div style={{ height: "80%", width: "100%"}}>
                                    {isLoading && item.i === currentChartId ? (
                                      <SearchLoader />
                                    ) : item.data ? (
                                      <MemoizedGetCharts
                                        data={item}
                                        index={index}
                                        theme={theme}
                                        chartTheme={chartTheme}
                                        editMode={editMode}
                                      />
                                    ) : (
                                      <Loader />
                                    )}
                                  </div>
                                  <div className='chartDate'>
                                      Last Updated:  {item.lastUpdated}
                                      </div>
                                </div>
                              </Box>                            
                            );
                          })}
                      </ResponsiveReactGridLayout>
                    </Paper>
                    <div> ..</div>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Box>
        </Box>
      </div>

      {!createNewDashboard && (
        <AddChartTabs
          setIsAddChartOpened={setIsAddChartOpened}
          isAddChartOpened={isAddChartOpened}
          handleDashboardSave={handleDashboardSave}
          defaultQuestion={defaultQuestion}
          getDefaultQuestions={getDefaultQuestions}
          getRefreshDashboardData={getRefreshDashboardData}
        />
      )}

      <ToastContainer />
    </div>
  );
}

export default Dashboard;
