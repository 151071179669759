import EChartsReact from "echarts-for-react";
import { setLineChartOption, setOption } from "../../services/ChartService";
import { generateDataForBarChart } from "../../services/DataService";
import * as echarts from "echarts";
import * as XLSX from "xlsx";

export default function BasicLineChart(props) {
  let chartModelColor = [];
  chartModelColor = props.chartTheme;
  var option = {};
  var chartType = "line";
  var title = "";
  var backgroundStyleColor = "#FF0000";
  var titleColorTheme = props.theme === "light" ? "#4F4F79" : "#9090BB";
  var itemStyleColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: chartModelColor[0].mainColor,
    },
    {
      offset: 1,
      color: chartModelColor[0].gradientColor,
    },
  ]);
  var XaxisChartName = props.data.data[0]?.xAxisName;
  var YaxisChartName = props.data.data[0]?.yAxisName;

  const compareByAge = (a, b) => {
    const ageA = parseInt(a[XaxisChartName]);
    const ageB = parseInt(b[XaxisChartName]);
    return ageA - ageB;
  };

  var dataArray = props.data.data.map((item) => {
    return { name: item['x'], value: item['y'] };
  })
  let yAxisData = {
    type: "value",
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.25,
        color: props.theme === "light" ? "#4F4F7935" : "#9090BB35",
      },
    },
  };
  const xAxisData = props.data.data.map(item => item.x);

  const obj = generateDataForBarChart(dataArray, XaxisChartName);

  function initData() {
    option = setLineChartOption(
      xAxisData,
      yAxisData,
      chartType,
      dataArray,
      title,
      itemStyleColor,
      titleColorTheme,
      XaxisChartName,
      YaxisChartName,
      obj.categories
    );
  }

  initData();

  function onChartClick(params, echarts) {
    var obj = { [XaxisChartName]: params.name };
    props.filterChart(obj);
  }

  return (
    <>
      {props.editMode ? (
        <EChartsReact
          option={option}
          onEvents={{
            click: onChartClick,
          }}
          style={{ height: "100%" }}
        />
      ) : (
        <EChartsReact option={option} style={{ height: "100%" }} />
      )}
    </>
  );
}
