import EChartsReact from "echarts-for-react";
import * as echarts from "echarts";
import {
  setCustomisedPieOption,
  setCustomisedPieOption1,
} from "../../services/ChartService";
import { generateDataForPieChart } from "../../services/DataService";

function DefaultPieChart(props) {
  // let chartModelColor = [];
  // chartModelColor = props.chartTheme;
  // let modelColor = [];
  // modelColor = chartModelColor;
  // var option = {};
  // var chartType = "pie";
  // var radius = "75%";
  // var roseType = "radius";
  // var center = ["50%", "50%"];
  // var animationType = "scale";
  // var animationEasing = "elasticOut";
  // var itemStyleColor = "#c23532";
  // // var animationDelay = () => function (idx) {
  // //       return Math.random() * 200;
  // //     }
  // var title = "";
  // var titleColorTheme = props.theme === "light" ? "#4F4F79" : "#9090BB";
  // var XaxisChartName = props.data.data[0].xAxisName;
  // var YaxisChartName = props.data.data[0].yAxisName;

  // var chartData = props.data.data.map(item => item.y);

  // var dataArray = props.data.data.map((item) => {
  //   return { name: item['x'], value: item['y'] };
  // });

  // function initData() {
  //   option = setCustomisedPieOption(
  //     title,
  //     titleColorTheme,
  //     chartType,
  //     dataArray,
  //     chartData,
  //     roseType,
  //     radius,
  //     center,
  //     animationType,
  //     animationEasing,
  //     chartModelColor,
  //     XaxisChartName,
  //     YaxisChartName
  //   );
  // }

  // initData();

  // function onChartClick(params, echarts) {
  //   var obj = { [props.data.chartProps.xAxis]: params.name };
  //   props.filterChart(obj);
  // }

  var seriesData = props.data.data.map(item => ({ value: item.y, name: item.x }));

  const option = {
    series: [
      {
        data: seriesData,
        type: 'pie',
        label: {
          show: true,
          formatter: '{b}: {c} ({d}%)', // Display format with name, value, and percentage
        },
      }
    ]
  }

  return (
    <>
      {props.editMode ? (
        <EChartsReact
          option={option}
          // onEvents={{
          //   click: onChartClick,
          // }}
          style={{ height: "100%" }}
        />
      ) : (
        <EChartsReact option={option} style={{ height: "100%" }} />
      )}
    </>
  );
}

export default DefaultPieChart;
