import React, { useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { GrFormClose } from "react-icons/gr";
import {
  getChartHeading,
  getChartType,
  getQueryData,
  getNeoForeQueryData
} from "../../apiServices/dashboard";

function Search({
  item,
  setIsLoading,
  setData,
  data,
  currentChartId,
  setIsSearchClicked,
}) {
  const [newQuestion, setNewQuestion] = useState(
    item.question === "Please enter your question?" ? "" : item.question
  );  
  console.log("param",data)

  const handleSearch = async () => {
    setIsLoading(true);
    console.log("newQuestion:::::25:::::::::::::::::::::",newQuestion)
    const queryDataRes = await getNeoForeQueryData(newQuestion,item.deptid);
    if(queryDataRes ==null || queryDataRes.length === 0){
      alert("Unable to execute the query. Please try a different query or contact support for assistance.")
      setIsLoading(false);
      return ;
    }
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    
    // Example usage
    var newdate = new Date();
    var lastUpdated = formatDate(newdate);
    const newData = data.map((item) => {
      const { i } = item;
      if (i === currentChartId) {
        return {
          ...item,
          data: queryDataRes,
          question: newQuestion,
          lastUpdated:lastUpdated
          
        };
      } else return item;
    });
    setData(newData);
    setIsLoading(false);
    setIsSearchClicked(false);
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      if (newQuestion.length === 0) {
        alert("Please provide proper question!");
      } else {
        handleSearch();
      }
    }
  };

  return (
    <div className='filterOptionsContainer'>
      <div>
        <SearchRoundedIcon className='filterOptionsContainerIcon' />
        <input
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
          placeholder='Please enter your question?'
          onKeyDown={(e) => handleSubmit(e)}
        />
        <GrFormClose
          className='filterOptionsContainerCloseIcon'
          onClick={() => setIsSearchClicked(false)}
        />
      </div>
    </div>
  );
}

export default Search;
