import "./Header.css";
import LogoIcon from './apple-icon.png'

const Logo = () => {
  console.log("${process.env.PUBLIC_URL}/apple-icon.png :::::::::::::::::::", `${process.env.PUBLIC_URL}/apple-icon.png`)
  return (
    <div className="logo">
      {/* <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 80 41"
        style={{ enableBackground: "new 0 0 80 41" }}
        backgroundColor="red"
        xmlSpace="preserve"
        width="80%"
        height="80%"
      >
        <style type="text/css">
          {`.st0{fill:#0D4A93;}
          .st1{fill:url(#Fill-14_1_);}`}
        </style>
        <title>ezest, logo</title>
        <desc>Created with Sketch.</desc>
        <g id="Latest">
          <g id="Work_V4" transform="translate(-50.000000, -17.000000)">
            <g id="Menu" transform="translate(50.000000, 17.000000)">
              <g id="ezest-_x2C_logo">
                <path
                  id="Fill-1"
                  className="st0"
                  d="M37.6,37.9c-0.2,0-0.5,0-0.7,0c-2.6,0-5.2,0-7.8,0h-0.6c0.2-0.2,0.3-0.3,0.4-0.5
					c4.1-4.1,8.1-8.2,12.2-12.3c1-1,1.6-2,1.8-3.2c0.1-0.6-0.1-0.9-0.8-1c-0.2,0-0.4,0-0.6,0c-3.7,0-7.4,0-11.1,0
					c-0.2,0-0.4,0-0.6,0c-0.8,0.1-1.2,0.4-1.5,1c-0.1,0.3-0.2,0.6-0.3,1c-0.2,0.8,0.1,1.1,1.1,1.1c2.1,0,4.2,0,6.3,0
					c0.6,0,1.3,0,2,0c-0.2,0.2-0.3,0.3-0.4,0.4c-4.2,4.2-8.3,8.4-12.5,12.6c-1,1-1.4,2-1.6,3.1c-0.1,0.4,0.1,0.7,0.6,0.8
					c0.2,0,0.4,0.1,0.6,0.1c4,0,8,0,12,0c0.2,0,0.3,0,0.5,0c0.7-0.1,1.1-0.4,1.3-1c0.1-0.4,0.2-0.8,0.3-1.2
					C38.5,38.3,38.2,38,37.6,37.9"
                />
                <path
                  id="Fill-3"
                  className="st0"
                  d="M50.4,29.1c-0.2,0.9-0.3,1.3-0.6,2.2h-4.7c0.2-0.8,0.3-1,0.5-2c0.2-0.9,1.7-1.4,2.6-1.4
					C49.6,27.9,50.6,28.3,50.4,29.1 M51.9,25.5c-0.9-0.4-2-0.5-3.2-0.5c-3.3,0.1-6.1,2-6.9,4.7c-0.6,2.2-1.2,4.4-1.7,6.6
					c-0.5,2,0.4,3.7,2.4,4.3c1.6,0.5,3.3,0.5,5.1-0.1c2.2-0.7,3.7-2.1,4.2-3.9c0.2-0.8-0.1-1.1-1-1.1c-0.5,0-1,0-1.5,0
					c-0.5,0-0.9,0.2-1.1,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.4,0.7-1.3,1.1-2.5,1.1c-1,0-2.1-0.5-1.8-1.8c0.3-1.1,0.4-1.5,0.7-2.7h0.6
					c2.2,0,4.3,0,6.5,0c0.9,0,1.4-0.3,1.6-1c0.3-1.1,0.6-2.3,0.8-3.4C54.2,27.5,53.5,26.3,51.9,25.5"
                />
                <path
                  id="Fill-5"
                  className="st0"
                  d="M10.4,29.1c-0.2,0.9-0.3,1.3-0.6,2.2H5.1c0.2-0.8,0.3-1,0.5-2c0.2-0.9,1.7-1.4,2.6-1.4
					C9.6,27.9,10.6,28.3,10.4,29.1 M11.9,25.5C11,25.1,9.9,25,8.7,25c-3.3,0.1-6.1,2-6.9,4.7c-0.6,2.2-1.2,4.4-1.7,6.6
					c-0.5,2,0.4,3.7,2.4,4.3c1.6,0.5,3.3,0.5,5.1-0.1c2.2-0.7,3.7-2.1,4.2-3.9c0.2-0.8-0.1-1.1-1-1.1c-0.5,0-1,0-1.5,0
					c-0.5,0-0.9,0.2-1.1,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.4,0.7-1.3,1.1-2.5,1.1c-1,0-2.1-0.5-1.8-1.8c0.3-1.1,0.4-1.5,0.7-2.7h0.6
					c2.2,0,4.3,0,6.5,0c0.9,0,1.4-0.3,1.6-1c0.3-1.1,0.6-2.3,0.8-3.4C14.2,27.5,13.5,26.3,11.9,25.5"
                />
                <path
                  id="Fill-7"
                  className="st0"
                  d="M66.8,26c-1.2-0.9-2.9-1.1-4.7-0.9c-2,0.2-3.8,1-4.9,2.6c-1.2,1.7-1.5,3.3-0.3,4.8
					c0.6,0.8,1.6,1.2,2.7,1.6c0.9,0.3,1.7,0.7,2.5,1.1c0.9,0.5,0.7,2-0.3,2.5c-1,0.6-2,0.5-2.9,0.3c-0.8-0.2-0.9-0.8-0.7-1.5
					c0.1-0.4-0.1-0.7-0.7-0.7c-0.3,0-0.6,0-0.9,0c0,0,0,0,0,0c-0.2,0-0.5,0-0.7,0c-0.9,0-1.3,0.3-1.5,1.1c-0.4,1.5,0.1,2.6,1.4,3.4
					c2.2,1.2,4.8,1.1,7.7,0c0.9-0.4,1.6-1,2.1-1.7c1.7-2.5,1.4-5-1.5-6.1c-0.9-0.4-1.9-0.7-2.7-1.1c-0.4-0.2-0.7-0.4-0.9-0.7
					c-0.5-0.7-0.1-1.8,0.8-2.2c1.5-0.8,3.1-0.2,3,1.1c0,0,0,0.1,0,0.1c0,0.4,0.2,0.7,0.7,0.7c0.6,0,1.1,0,1.7,0c0.8,0,1.2-0.3,1.4-1
					C68.2,27.8,67.8,26.8,66.8,26"
                />
                <path
                  id="Fill-9"
                  className="st0"
                  d="M78.7,25.8c-0.9,0-2,0-2.9,0c0.1-0.2,0.1-0.4,0.1-0.5c0.2-0.7,0.7-2.6,0.9-3.3
					c0.2-0.7-0.1-1-1-1.1c-0.3,0-0.6,0-1,0c-1.2,0-1.5,0.2-1.8,1.2c-1.2,4.5-2.7,10.2-3.9,14.7c-0.1,0.3-0.1,0.5-0.2,0.8
					c-0.1,1.3,0.1,2.4,1.6,2.9c1.4,0.5,3,0.5,4.7,0.2c0.5-0.1,0.8-0.3,1-0.7c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.7-0.2-1-1.1-0.9
					c-0.4,0-0.8,0.1-1.2,0.1c-1.1,0-1.6-0.5-1.3-1.4c0.7-2.6,1.4-5.2,2-7.8c0-0.1,0.1-0.1,0.1-0.2h0.5c0.8,0,1.8,0,2.5,0
					c1,0,1.5-0.3,1.7-1.1c0-0.2,0.1-0.3,0.1-0.5C80.2,26.1,79.9,25.8,78.7,25.8"
                />
                <path
                  id="Fill-11"
                  className="st0"
                  d="M23.6,32.2c0.1-0.5,0.3-0.9,0.4-1.4c0.1-0.5-0.1-0.8-0.7-0.8c-2.2,0-3.4,0-5.7,0
					c-0.6,0-1,0.3-1.2,0.8c-0.1,0.5-0.3,0.9-0.4,1.4c-0.1,0.5,0.1,0.8,0.7,0.8c1.1,0,2.2,0,3.3,0c1.1,0,1.2,0,2.3,0
					C23.1,33,23.4,32.7,23.6,32.2"
                />

                <linearGradient
                  id="Fill-14_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="-70.7176"
                  y1="155.3331"
                  x2="-70.7176"
                  y2="154.3331"
                  gradientTransform="matrix(3.5093 0 0 -3.5093 261.5093 542.8735)"
                >
                  <stop offset="0" style={{ stopColor: "#F26539" }} />
                  <stop offset="1" style={{ stopColor: "#F58233" }} />
                </linearGradient>
                <path
                  id="Fill-14"
                  className="st1"
                  d="M65.9,3.2c0.1,0.5,0.4,1,0.9,1.3c0.6,0.4,1.3,0.4,2,0.2c0,0,0.4-0.2,1-0.2
					c1.4,0,2.4,1.1,2.2,2.5c-0.1,0.6-0.4,1.2-0.8,1.6c-0.3,0.3-0.6,0.5-1.1,0.7c-1.2,0.7-2.8,0.9-4.4,0.1c0,0-0.1,0-0.1-0.1
					c-1.5-0.7-3.4-0.7-5.2,0.2c-2.9,1.5-4.2,4.9-3,7.5c1.2,2.6,4.5,3.5,7.4,2c1.8-0.9,2.9-2.6,3.3-4.3c0,0,0-0.1,0-0.1
					c0.7-3,3.3-4.3,5.2-4.3c0.4,0,0.9,0.1,1.2,0.2c0.2,0.1,0.5,0.1,0.5,0.1c1.5,0.3,3.2-0.4,4.1-1.8c1.2-1.7,0.9-4-0.7-5
					c-1-0.6-2.3-0.6-3.4-0.1c0,0-0.1,0-0.1,0c-2.4,1-4-0.7-4.1-1.9c0,0,0-0.1,0-0.1c0-0.7-0.4-1.3-1-1.7C69.5,0.1,69.1,0,68.7,0
					c-0.9,0-1.7,0.4-2.3,1.2C65.9,1.8,65.8,2.6,65.9,3.2z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg> */}

<img src={LogoIcon} style={{width:'130px', height:'40px', paddingRight:'2%'}}></img>

    </div>
  );
};

export default Logo;
