export function setOption(
  titleColorTheme,
  xAxisData,
  yAxisData,
  chartType,
  dataArray,
  title,
  colorAreaStyle,
  showBackground,
  backgroundStyleColor,
  itemStyleColor,
  chartData,
  XaxisChartName,
  YaxisChartName
) {
  return {
    title: {
      text: title,
      textStyle: {
        fontSize: "15px",
        color: titleColorTheme,
      },
    },
    xAxis: {
      data: xAxisData,
      axisLabel: {
        show: true,
        interval: 0,
        overflow: xAxisData.length < 5 ? "auto" : "truncate",
        width: 30,
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        // Customize the tooltip content here
        const objectKeysOfDataArray = Object.keys(chartData[0]);

        const item = chartData.find(
          (item) =>
            item[XaxisChartName] === params.name &&
            item[YaxisChartName] === params.value
        );
        let tooltipString = "";
        if (item) {
          const data = objectKeysOfDataArray.map((keys) => {
            const splitKey = keys.split(".");
            tooltipString = tooltipString + ` ${splitKey[1]} : ${item[keys]}, `;
            return "";
          });
          return tooltipString;
        }
        return ""; // Return an empty string if the item is not found
      },
    },
    yAxis: yAxisData,

    animationDurationUpdate: 500,
    series: {
      type: chartType,
      data: dataArray.map((item) => item.value),
      areaStyle: {
        color: colorAreaStyle,
      },
      showBackground: showBackground,
      progress: {
        show: true,
      },
      itemStyle: {
        color: itemStyleColor,
      },
      detail: {
        valueAnimation: true,
        formatter: "{value}",
      },
    },
    backgroundStyle: {
      color: backgroundStyleColor,
    },
  };
}

export function setBarChartOption(
  xAxisData,
  XaxisChartName,
  YaxisChartName,
  yAxisData,
  chartType,
  dataArray,
  title,
  titleColorTheme,
  itemStyleColor,
  barBorderRadius,
  axisLabel,
  chartData
) {
  return {
    title: {
      text: title,
      textStyle: {
        fontSize: "15px",
        color: titleColorTheme,
      },
    },
    xAxis: {
      data: xAxisData,
      name: XaxisChartName,
      axisLabel: axisLabel,
      axisLine: {
        lineStyle: {
          color: "#757595",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      trigger: "axis", // Use 'axis' trigger for bar charts
      axisPointer: {
        type: "shadow", // Specify the type of axis pointer
      },
      formatter: function (params) {
        const name = params[0].name; // x-axis name
        const value = params[0].value; // y-axis value

        return `${name}: ${value}`;
      },
    },
    yAxis: {
      type: "value",
      name: YaxisChartName, 
      ...yAxisData,
    },
    grid: {
      left: "3%",
      right: "10%",
      bottom: "3%",
      containLabel: true,
    },
    animationDurationUpdate: 500,
    barWidth: "12px",
    series: {
      type: chartType,
      data: dataArray.map((item) => item.value),
      progress: {
        show: true,
      },

      detail: {
        valueAnimation: true,
        formatter: "{value}",
      },
      itemStyle: {
        barBorderRadius: [...barBorderRadius], // Specify the border radius
        borderType: "solid",

        color: itemStyleColor,
      },
    },
  };
}

export function setStackAreaChartOption(
  xAxisData,
  XaxisChartName,
  yAxisData,
  splitLineColorTheme,
  chartType,
  dataArray,
  title,
  titleColorTheme,
  barBorderRadius,
  colorAreaStyle,
  axisLabel
) {
  return {
    title: {
      text: title,
      textStyle: {
        fontSize: "15px",
        color: titleColorTheme,
      },
    },
    xAxis: {
      data: xAxisData,
      name: XaxisChartName,
      axisLabel: axisLabel,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: "#757595",
        },
      },
    },
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    grid: {
      left: "3%",
      right: "10%",
      bottom: "3%",
      containLabel: true,
    },
    legend: {
      data: [
        "Requirement",
        "Process Folder",
        "Process",
        "Performance Indicator",
        "Report",
        "Product Folder",
        "Product",
      ],
      textStyle: {
        color: "#757595",
      },
    },
    yAxis: yAxisData,
    animationDurationUpdate: 500,
    barWidth: "10px",
    series: [
      {
        type: chartType,
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 1,
          color: colorAreaStyle,
        },

        data: dataArray,
      },
    ],
    backgroundStyle: {
      // color: backgroundStyleColor
    },
  };
}

export function setCustomisedPieOption(
  title,
  titleColorTheme,
  chartType,
  dataArray,
  chartData,
  roseType,
  radius,
  center,
  animationType,
  animationEasing,
  chartModelColor,
  XaxisChartName,
  YaxisChartName
) {
  let modelColor = [];
  modelColor = chartModelColor;
  return {
    title: {
      text: title,
      textStyle: {
        fontSize: "15px",
        color: titleColorTheme,
      },
    },
    // tooltip: {
    //   trigger: "item",
    //   formatter: ' {b}: {c} ({d}%)',
    // },
    tooltip: {
      trigger: "axis", // Use 'axis' trigger for bar charts
      axisPointer: {
        type: "shadow", // Specify the type of axis pointer
      },
      formatter: function (params) {
        const name = params[0].name; // x-axis name
        const value = params[0].value; // y-axis value

        return `${name}: ${value}`;
      },
    },
    visualMap: {
      show: false,
      min: 80,
      max: 600,
      inRange: {},
    },
    series: [
      {
        type: chartType,
        data: dataArray.map((item, index) => {
          return {
            name: item["name"],
            value: item["value"],

            itemStyle: {
              color: modelColor[index % 9].mainColor,
            },
          };
        }),
        // associate the series to be animated by id
        roseType: roseType,
        radius: radius,
        universalTransition: true,
        animationDurationUpdate: 1000,
        center: center,
        animationType: animationType,
        animationEasing: animationEasing,
        // animationDelay:animationDelay,
        label: {
          color: "#757595",
        },
        labelLine: {
          lineStyle: {
            color: "#B7B3C7",
          },
          smooth: 0.2,
          length: 10,
          length2: 20,
        },
      },
    ],
  };
}

export function setBubbleChartOption(
  bubleSeriesData,
  XaxisChartName,
  YaxisChartName,
  yAxisData,
  chartType,
  dataArray,
  title,
  titleColorTheme,
  itemStyleColor,
  axisLabel,
  chartModelColor,
  chartData
) {
  return {
    title: {
      text: title,
      top: "-4px",
      textStyle: {
        fontSize: "15px",
        color: titleColorTheme,
      },
    },
    xAxis: {
      // data: {},
      name: XaxisChartName,
      axisLabel: axisLabel,
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      axisLine: {
        lineStyle: {
          color: "#757595",
        },
      },
    },
    yAxis: yAxisData,
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        // Customize the tooltip content here
        const objectKeysOfDataArray = Object.keys(chartData[0]);
        const item = chartData.find(
          (item) =>
            item[XaxisChartName] === params.value[0] &&
            item[YaxisChartName] === params.value[1]
        );

        let tooltipString = "";
        if (item) {
          const data = objectKeysOfDataArray.map((keys) => {
            const splitKey = keys.split(".");
            tooltipString = tooltipString + ` ${splitKey[1]} : ${item[keys]}, `;
            return "";
          });
          return tooltipString;
        }
        return ""; // Return an empty string if the item is not found
      },
    },
    legend: {
      right: "1px",
      top: "0px",
      data: ["Process", "Requirement"],
      textStyle: {
        color: "#757595",
      },
    },
    grid: {
      left: "3%",
      right: "10%",
      bottom: "3%",
      containLabel: true,
    },

    series: [
      {
        name: "type",
        type: chartType,
        data: bubleSeriesData,
        symbolSize: function (data) {
          const minSize = 15;
          const maxSize = 35;
          let dataTry = dataArray.map((item) => item["value"]);
          const minDataValue = Math.min(...dataTry.map(Number));
          const maxDataValue = Math.max(...dataTry.map(Number));

          const scaledSize =
            ((data[1] - minDataValue) / (maxDataValue - minDataValue)) *
              (maxSize - minSize) +
            minSize;
          return scaledSize;
        },
        emphasis: {
          focus: "series",
          label: {
            show: true,
            formatter: function (param) {
              return param.data[3];
            },
            position: "top",
          },
        },
        itemStyle: {
          color: itemStyleColor,
        },
      },
    ],
  };
}

export function setLineChartOption(
  xAxisData,
  yAxisData,
  chartType,
  dataArray,
  title,
  itemStyleColor,
  titleColorTheme,
  XaxisChartName,
  YaxisChartName,
) {
  return {
    title: {
      text: title,
      textStyle: {
        fontSize: "15px",
        color: titleColorTheme,
      },
    },
    xAxis: {
      type: "category",
      name: XaxisChartName,
      data: xAxisData,
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return ""
        console.log("\n\nline chart::::::::::::::::\n\n", params)

        const name = params[0].name; // x-axis name
        const value = params[0].value; // y-axis value
    
        return `${name}: ${value}`;
      },
    },
    yAxis: {
      type: "value",
      name: YaxisChartName,
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.25,
          color: "#4F4F7935",
        },
      },
    },
    animationDurationUpdate: 500,
    series: [
      {
        type: chartType,
        data: dataArray,
        itemStyle: {
          color: itemStyleColor,
        },
        detail: {
          valueAnimation: true,
          formatter: "{value}",
        },
      },
    ],
  };
}
