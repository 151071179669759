import EChartsReact from "echarts-for-react";
import { setOption } from "../../services/ChartService";
import { generateDataForBarChart } from "../../services/DataService";
import * as echarts from "echarts";

export default function SimpleGuage(props) {
  let chartModelColor = [];
  chartModelColor = props.chartTheme;
  var option = {};
  var chartType = "gauge";
  var title = "";
  var chartData = props.chartData;
  var itemStyleColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: chartModelColor[0].mainColor,
    },
    {
      offset: 1,
      color: chartModelColor[0].gradientColor,
    },
  ]);
  function initData() {
    var obj = generateDataForBarChart(chartData, props.data.chartProps.xAxis);
    var dataArray = props.chartData.map((item) => {
      return { name: item.type, value: item.value };
    });
    option = setOption(
      obj.categories,
      {},
      chartType,
      dataArray,
      title,
      "",
      "",
      "",
      itemStyleColor,
      chartModelColor
    );
  }

  initData();
  return (
    <>
      <EChartsReact option={option} style={{ marginTop: "20px",height:"100%" }} />
    </>
  );
}
