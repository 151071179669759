import React, { useState, useEffect, useRef } from "react";
import { CiMenuKebab } from "react-icons/ci";

function AxisMenu({ currentChartId, setData, data, currentData }) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isXaXisMenuOpened, setIsXaXisMenuOpened] = useState(false);
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const [selectedXAxis, setselectedXAxis] = useState("");
  const filterUpdateChartRef = useRef(null);
  const [ismultipleXAxisPresent, setIsmultipleXAxisPresent] = useState(false);

  const handleYAxisChange = (axis) => {
    const { ogName, displayName } = axis;
    setselectedXAxis(displayName);

    const updatedData = data.map((item) => {
      if (item.i === currentChartId) {
        return {
          ...item,
          chartProps: { ...item.chartProps, xAxis: ogName },
        };
      }

      return item;
    });
    setData(updatedData);
    setIsMenuOpened(false);
  };

  const handleAxisMenu = () => {
    setIsMenuOpened(!isMenuOpened);
    const singleChartData = data.filter((item) => item.i === currentChartId);
    const singleChartDataObject = singleChartData[0].data[0];
    let splitValue, otherKeys, countKey, dataKeys, chartProps;
    dataKeys = Object.keys(singleChartDataObject);

    //static logic need to came data count or it will take first number value to y axis
    countKey = dataKeys.find((key) => {
      splitValue = key.split(".");
      if (splitValue && splitValue[1]?.toLowerCase() === "count") {
        return key;
      } else if (typeof singleChartDataObject[key] === "number") {
        return key;
      }
    });
    setYAxis(countKey);

    otherKeys = dataKeys.filter((key) => key !== countKey);

    const modifingXaxis = otherKeys.map((keys) => {
      let finalKey;
      if (keys.includes(".")) {
        finalKey = keys.split(".")[1];
      } else {
        finalKey = keys;
      }
      return {
        displayName: finalKey,
        ogName: keys,
      };
    });
    setXAxis(modifingXaxis);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterUpdateChartRef.current &&
        !filterUpdateChartRef.current.contains(event.target)
      ) {
        setIsMenuOpened(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    // if(currentData.data && currentData.data.length > 0){
    //   const currentObjectData = currentData.data[0]
    //   const dataKeys = Object.keys(currentObjectData)
    //   // console.log("currentObjectData:::::::::::", currentObjectData)
    //   const otherKeys = dataKeys.filter(key => {
    //     const splitValue = key.split(".")
    //     // console.log("singleChartDataObject[key]:::::::::::::::::",typeof singleChartDataObject[key])
    //     if (splitValue && splitValue[1].toLowerCase() !== "count" && (typeof currentObjectData[key] !== 'boolean')) {
    //       return key
    //     }
    //   });

    //   if(otherKeys && otherKeys.length > 1){
    //     console.log("iotherKeys.length:::::::::::::::::",otherKeys.length)
    //     setIsmultipleXAxisPresent(true)
    //   }
    //   else{
    //     setIsmultipleXAxisPresent(false)
    //   }
    // }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // console.log("ismultipleXAxisPresent:::::::::::::::::",ismultipleXAxisPresent)
  return (
    <div ref={filterUpdateChartRef}>
      {currentData && (
        <>
          <CiMenuKebab
            onClick={() => handleAxisMenu()}
            style={{ fontSize: "20px", color: "rgba(58, 0, 128, 0.742)" }}
          />
          {isMenuOpened && (
            <div className='filterChartAddDropDown'>
              {" "}
              {/*hideChartFilter*/}
              <div style={{ textAlign: "center" }}>Dimensions</div>
              {
                <div className='filterChartInnerDropDown'>
                  <div onMouseEnter={() => setIsXaXisMenuOpened(true)}>
                    xAxis
                  </div>
                  <div>yAxis</div>
                </div>
              }
            </div>
          )}
          {isXaXisMenuOpened && (
            <div
              className='filterChartAddDropDown'
              onMouseLeave={() => setIsXaXisMenuOpened(false)}
            >
              <p className='filterChartAddDropDownp'>xAxis</p>
              <div className='filterChartInnerDropDown'>
                {isXaXisMenuOpened &&
                  xAxis.map((axis) => {
                    return (
                      <div
                        className='filterChartDropDownChild'
                        onClick={() => handleYAxisChange(axis)}
                      >
                        {axis.displayName}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AxisMenu;

// const handleAxisMenu = () => {
//   setIsMenuOpened(!isMenuOpened)
//   const singleChartData = data.filter(item => item.i === currentChartId)
//   const singleChartDataObject = singleChartData[0].data[0]
//   // console.log("singleChartDataObject::::::::::::",singleChartDataObject)
//   let splitValue, otherKeys, countKey, dataKeys, chartProps
//   dataKeys = Object.keys(singleChartDataObject)
//   countKey = dataKeys.find(key => {
//     splitValue = key.split(".")
//     if (splitValue && splitValue[1].toLowerCase() === "count") {
//       return key
//     }
//   });
//   setYAxis(countKey)

//   otherKeys = dataKeys.filter(key => {
//     splitValue = key.split(".")
//     if (splitValue && splitValue[1].toLowerCase() !== "count" && (typeof singleChartDataObject[key] !== 'boolean')) {
//       return key
//     }
//   });

//   const modifingXaxis = otherKeys.map((keys) => {
//     const splitedKeys = keys.split(".")
//     return {
//       displayName: splitedKeys[1],
//       ogName: keys
//     }
//   })
//   setXAxis(modifingXaxis)
// }
