import { Box, Typography } from "@mui/material";
import React from "react";

function CardWrapper(props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        borderRadius: "5px",
        backgroundColor: "rgb(243, 246, 249)",
        px: { xs: "16px", sm: "0px" },
        py: { xs: "16px", sm: "0px" },
      }}
    >
      <Typography variant='h6' sx={{ fontSize: "15px" }}>
        {props.data}
      </Typography>
    </Box>
  );
}

export default CardWrapper;
