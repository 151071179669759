import React, { useEffect, useState } from 'react'
import CardWrapper from '../CardWrapper'

function CardChart({ data }) {
  const [updatedCardData, setUpdatedCardData] = useState({
    label: "",
    value: 0
  })
  // console.log("data::::::", data)
  useEffect(() => {
    if (data && data.data.length > 1) {
      setUpdatedCardData(false)
      return
    }
    
    if (data && data.data.length === 1 && data.data[0].type === "") {
      setUpdatedCardData({
        label: "",
        value:0
      })
     return 
    }
    const value = data && data.data[0]
    const dataKeys = Object.keys(value)
    const countKey = dataKeys.find(key => {
      const splitValue = key.split(".")
      if (splitValue && splitValue[1].toLowerCase() === "count") {
        return key
      }
    });

    const labelKey = dataKeys.find(key => {
      const splitValue = key.split(".")
      if (splitValue && splitValue[1].toLowerCase() !== "count" && (typeof value[key] !== 'boolean')) {
        return key
      }
    });

    setUpdatedCardData({
      label: value[labelKey],
      value: value[countKey]
    })
  }, [])
  return (
    <div className='CardChart'>
      {
        updatedCardData ?
        <>
          <div className='value'>
            {updatedCardData.value}
          </div> 
          <div className='label'>
        {updatedCardData.label}
      </div>
        </>
          :
          // <div className='ErrorCard'>
          //   Card is not suitable for the given data please choose some other chart
          // </div>
          <CardWrapper data="Card is not suitable for the given data please choose some other chart" />
      }  
    </div>
  )
}

export default CardChart