import React, { useState } from "react";
import "./Sidebar.css";
import { AiOutlineDashboard} from "react-icons/ai";
import ExtendedSidebar from "./ExtendedSidebar";
import "react-toastify/dist/ReactToastify.css";
import DialogBox from "../Share/DialogBox";

function Sidebar({remainingDashboard, handleModelOpen }) {
  const [isNewSidebarOpen, setIsNewSidebarOpen] = useState(false);

  return (
    <>
      <div className="sidebarContainer">
        <div
          className="sidebarInnerContainer1 sidebarInnerContainer"
          onMouseEnter={() => {
            remainingDashboard && remainingDashboard.length > 0
              ? setIsNewSidebarOpen(false)
              : setIsNewSidebarOpen(true);
          }}
        >
          <span title="Dashboard">
            <AiOutlineDashboard className="sidebarInnerContainerIcon" />
          </span>
          <div>Load Dashboard</div>
        </div>
        <hr style={{ width: "80%" }}></hr>

        <DialogBox/>
        <hr style={{ width: "80%" }}></hr>

        <ExtendedSidebar
          setIsNewSidebarOpen={setIsNewSidebarOpen}
          isNewSidebarOpen={isNewSidebarOpen}
          remainingDashboard={remainingDashboard}
          handleModelOpen={handleModelOpen}
        />
      </div>
    </>
  );
}

export default Sidebar;
