import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import { AiFillPieChart } from "react-icons/ai";
import { Tooltip } from "@mui/material";
import { chartOptions } from "../../util/chartOptions";

function FilterChart({ currentChartId, setData, data, setIsLoading, type }) {
  const options = chartOptions;

  // const [selectedOption, setSelectedOption] = useState(type ? type : "");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterChartRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterChartRef.current &&
        !filterChartRef.current.contains(event.target)
      ) {
        setIsFilterOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const handleSelectChange = (name) => {
  //   setSelectedOption(name);
  // };

  const handleChartChange = (name) => {

    console.log("name:::::::::::::::::::::::::::::::",name)
    setIsLoading(true);
    const newData = data.map((item) => {
      const { i } = item;
      if (i === currentChartId) {
        return {
          ...item,
          type: name,
        };
      } else return item;
    });
    setData(newData);
    setIsLoading(false);
    setIsFilterOpen(false);
  };

  // useEffect(() => {
  //   handleChartChange()
  // }, [selectedOption])

  return (
    <div className='filterChartContainer' ref={filterChartRef}>
      <Tooltip title='Chart Types' placement='right'>
        <AiFillPieChart
          style={{ fontSize: "20px", color: "rgba(58, 0, 128, 0.742)" }}
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        />
      </Tooltip>
      {isFilterOpen && (
        <div className='filterChartDropDown'>
          {" "}
          {/*hideChartFilter*/}
          {options.map((option) => {
            const { value, name } = option;
            return (
              <div
                className='filterChartDropDownChild'
                onClick={() => handleChartChange(value)}
              >
                {name}
              </div>
            );
          })}
        </div>
      )}

      {/* <select value={selectedOption} onChange={handleSelectChange} >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        ))}
      </select> */}
    </div>
  );
}

export default FilterChart;
