import EChartsReact from "echarts-for-react";
import * as echarts from "echarts";
import { setBarChartOption, setOption } from "../../services/ChartService";
import { generateDataForBarChart } from "../../services/DataService";

function DefaultBarChart(props) {

  console.log("props:::::::::::::::::::::::::::",props)
  let chartModelColor = [];
  chartModelColor = props.chartTheme;
  var option = {};
  var chartType = "bar";
  var title = "";
  var splitLineColorTheme = props.theme === "light" ? "#4F4F7935" : "#9090BB35";
  var titleColorTheme = props.theme === "light" ? "#4F4F79" : "#9090BB";
  var itemStyleColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: chartModelColor[1].mainColor,
    },
    {
      offset: 1,
      color: chartModelColor[0].gradientColor,
    },
  ]);
  var barBorderRadius = [50, 50, 0, 0];
  var XaxisChartName = props.data.data[0].xAxisName;
  var YaxisChartName = props.data.data[0].yAxisName;
  // var XaxisChartName = props.data.chartProps.xAxis;
  // var YaxisChartName = props.data.chartProps.yAxis;

  let yAxisData = {
    type: "value",
    axisLine: {
      lineStyle: {
        color: "#757595",
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.25,
        color: splitLineColorTheme,
      },
    },
  };

  var chartData = props.data.data.map(item => item.y);

  var dataArray = props.data.data.map((item) => {
    return { name: item['x'], value: item['y'] };
  });

  const xAxisData = props.data.data.map(item => item.x);

  function initData() {
    var obj = generateDataForBarChart(chartData, XaxisChartName);

    var axisLabel = {
      show: true,
      interval: 0,
      overflow: obj.categories.length < 5 ? "auto" : "truncate",
      width: 30,
      hideOverlap: true,
    };

    option = setBarChartOption(
      xAxisData,
      XaxisChartName,
      YaxisChartName,
      yAxisData,
      chartType,
      dataArray,
      title,
      titleColorTheme,
      itemStyleColor,
      barBorderRadius,
      axisLabel,
      chartData,
      obj.categories
    );
  }

  initData();


  // var xAxisData = props.data.data.map(item => item.x);
  // var seriesData = props.data.data.map(item => item.y)

  // const option = {
  //   xAxis: {
  //     type: 'category',
  //     data: xAxisData
  //   },
  //   yAxis: {
  //     type: 'value'
  //   },
  //   series: [
  //     {
  //       data: seriesData,
  //       type: 'bar',
  //     }
  //   ]
  // }

  return (
    <>
      {props.editMode ? (
        <EChartsReact
          option={option}
          // onEvents={{
          //   click: onChartClick,
          // }}
          style={{ height: "100%" }}
        />
      ) : (
        <EChartsReact option={option} style={{ height: "100%" }} />
      )}
    </>
  );
}

export default DefaultBarChart;
