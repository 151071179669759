import EChartsReact from "echarts-for-react";
import * as echarts from "echarts";
import {
  setBarChartOption,
  setBubbleChartOption,
  setOption,
  setStackAreaChartOption,
} from "../../services/ChartService";
import { generateDataForBarChart } from "../../services/DataService";

function DefaultBubbleChart(props) {
  let chartModelColor = [];
  chartModelColor = props.chartTheme;
  var option = {};
  var chartType = "scatter";
  var title = "";
  var splitLineColorTheme = props.theme === "light" ? "#4F4F7935" : "#9090BB35";
  var titleColorTheme = props.theme === "light" ? "#4F4F79" : "#9090BB";
  var itemStyleColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: chartModelColor[0].mainColor,
    },
    {
      offset: 1,
      color: chartModelColor[0].gradientColor,
    },
  ]);
  var XaxisChartName = props.data.chartProps.xAxis;
  var YaxisChartName = props.data.chartProps.yAxis;

  var barBorderRadius = [50, 50, 0, 0];

  let yAxisData = {
    axisLine: {
      lineStyle: {
        color: "#757595",
      },
    },
    // scale: true,
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.25,
        color: props.theme === "light" ? "#4F4F7935" : "#9090BB35",
      },
    },
  };
  var chartData = props.data.data ? props.data.data : [{}];
  let bubleSeriesData = chartData.map((item) => {
    return [item[XaxisChartName], item[YaxisChartName]];
  });

  function initData() {
    var obj = generateDataForBarChart(chartData, XaxisChartName);

    var dataArray = chartData.map((item) => {
      return { name: item[XaxisChartName], value: item[YaxisChartName] };
    });

    var axisLabel = {
      show: true,
      interval: 0,
      overflow: obj.categories.length < 5 ? "auto" : "truncate",
      width: 30,
    };
    option = setBubbleChartOption(
      bubleSeriesData,
      XaxisChartName,
      YaxisChartName,
      yAxisData,
      chartType,
      dataArray,
      title,
      titleColorTheme,
      itemStyleColor,
      axisLabel,
      chartModelColor,
      chartData
    );
  }
  initData();

  function onChartClick(params, echarts) {
    var obj = { [props.data.chartProps.xAxis]: params.name };
    props.filterChart(obj);
  }

  return (
    <>
      {props.editMode ? (
        <EChartsReact
          option={option}
          onEvents={{
            click: onChartClick,
          }}
          style={{ height: "100%" }}
        />
      ) : (
        <EChartsReact option={option} style={{ height: "100%" }} />
      )}
    </>
  );
}

export default DefaultBubbleChart;
