import React, { useState, memo, useEffect } from "react";
import "../Dashboard/styles.css";
import { Grid, Container, Box, Paper } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import GetCharts from "../GetCharts";
import "../Dashboard/styles.css";
import Loader from "../Loader/Loader";
import { getDashData } from "../../apiServices/dashboard";

function ShareDashboard() {
  const MemoizedGetCharts = memo(GetCharts);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState("light");
  const [editMode, setEditMode] = useState(true);
  const [layouts, setLayouts] = useState();
  console.log("layouts::", layouts);
  const [chartTheme, setChartTheme] = useState([
    { mainColor: "#6C69FF", gradientColor: "#3834FE" },
    { mainColor: "#2DE0D2", gradientColor: "#29CCBF" },
    { mainColor: "#EFDE41", gradientColor: "#FFFFFF" },
    { mainColor: "#29CCBF", gradientColor: "#2DE0D2" },
    { mainColor: "#F85F3E", gradientColor: "#FFFFFF" },
    { mainColor: "#3834FE", gradientColor: "#FFFFFF" },
    { mainColor: "#AE1AE1", gradientColor: "#FFFFFF" },
    { mainColor: "#85C236", gradientColor: "#FFFFFF" },
    { mainColor: "#670EAC", gradientColor: "#FFFFFF" },
  ]);

  const ResponsiveReactGridLayout = WidthProvider(Responsive);
  const fetchData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const dashName = JSON.parse(localStorage.getItem("dashboardTabs"));

    const queryParameters = new URLSearchParams(window.location.search);
    const id = queryParameters.get("value");

    dashName.map((dash) => {
      if (id === dash.id) {
        getDashboardData(user.username, dash);
      }
    });
  };
  const getDashboardData = async (userName, current_dashboard) => {
    const dashboardData = await getDashData(userName, current_dashboard);
    setData(dashboardData);
    setLayouts(dashboardData.layouts);
    setIsLoading(false);
  };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            overflow: "auto",
            backgroundColor: "#dce7e9",
          }}
        >
          <ResponsiveReactGridLayout
            layouts={layouts}
            breakpoints={{
              lg: 1200,
              md: 996,
              sm: 768,
              xs: 480,
              xxs: 0,
            }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            margin={[20, 10]}
            isDraggable={false}
            isResizable={false}
          >
            {data?.childElements?.map((item, index) => {
              return (
                <Paper
                  key={item.i}
                  data-grid={item.layout}
                  sx={{
                    border: "1px solid rgb(225, 240, 255)",
                    transform: " translate(10px, 10px)",
                    width: " auto",
                    height: "100%",
                  }}
                >
                  <div className='chartTitle'>{item.title}</div>
                  <div style={{ height: "80%" }}>
                    <MemoizedGetCharts
                      data={item}
                      index={index}
                      theme={theme}
                      chartTheme={chartTheme}
                    />
                  </div>
                </Paper>
              );
            })}
          </ResponsiveReactGridLayout>
        </Box>
      )}
    </>
  );
}

export default ShareDashboard;
