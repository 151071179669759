import React from "react";
import "./styles.css";

const SearchLoader = () => {
  return (
    <div style={{height: "100%", display : "flex", justifyContent : "center", alignItems : "center"}}>
      <div className="loader__spinner"></div>
    </div>
    // <span class="loader"></span>
  );
};

export default SearchLoader;
