import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { graphActions } from "../../reduxStore/slices/graphSlice";
import { beGraphTestData } from "../../util/beGraphTestData";

import EChartsReact from "echarts-for-react";
// import * as echarts from "echarts";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import CardWrapper from "../CardWrapper";
// import CardComponent from "../components/CardComponent";

const GraphChart = ({ data }) => {
  const reduxNodes = useSelector((state) => state.graph.nodes);
  const reduxFixedNodes = useSelector((state) => state.graph.fixedNodes);
  const reduxRelationships = useSelector((state) => state.graph.relationships);

  //   const graphTestData = beGraphTestData;
  const graphTestData = data.data;

  const neofourResultData = useSelector(
    (state) => state.graph.neofourResultData
  );
  console.log("neofourResultData ", neofourResultData);

  const neoForeQueryData = useSelector((state) => state.queryData.queryData);
  console.log("neoForeQueryData from selecter", neoForeQueryData);

  const dispatch = useDispatch();

  const [selectedNodeId, setSelectedNodeId] = useState();
  const [nodeId, setNodeId] = useState();

  useEffect(() => {
    if (
      graphTestData &&
      "nodes" in graphTestData &&
      "relationships" in graphTestData
    ) {
      const neoFourData = graphTestData;
      dispatch(graphActions.setNeofourResultData(neoFourData));
      const nodes = neoFourData.nodes.map((element) => {
        return { id: String(element.id), name: element.properties.name };
      });
      console.log("nodes from useEffect", nodes);
      dispatch(graphActions.setNodes(nodes));
      dispatch(graphActions.setFixedNodes(nodes));

      const relationships = neoFourData.relationships.map((element) => {
        return {
          source: element.startElementId,
          target: element.endElementId,
        };
      });
      console.log("relationships from useEffect", relationships);
      dispatch(graphActions.setRelationships(relationships));
    }
  }, []);

  useEffect(() => {
    if (nodeId) {
      const oneNode = reduxFixedNodes.find((element) => element.id === nodeId);
      dispatch(graphActions.setNodes([oneNode]));
    }
  }, [nodeId]);

  useEffect(() => {
    const filteredRelationships = reduxRelationships.filter(
      (rel) => rel.source === selectedNodeId || rel.target === selectedNodeId
    );

    if (selectedNodeId) {
      if (filteredRelationships.length > 0) {
        let resultIdArray = [];

        filteredRelationships.forEach((obj) => {
          if (!resultIdArray.includes(obj.source)) {
            resultIdArray.push(obj.source);
          }
          if (!resultIdArray.includes(obj.target)) {
            resultIdArray.push(obj.target);
          }
        });

        const filterNodeArray = reduxFixedNodes.filter((item) =>
          resultIdArray.includes(item.id)
        );

        dispatch(graphActions.setNodes(filterNodeArray));
      } else {
        const filterNode = reduxFixedNodes.find(
          (item) => item.id === selectedNodeId
        );
        dispatch(graphActions.setNodes([filterNode]));
      }
    }
  }, [selectedNodeId]);

  const handleNodeClick = useCallback(
    (params) => {
      console.log("params", params);
      const clickedNodeId = params.data.id;
      setSelectedNodeId(clickedNodeId);
    },
    [selectedNodeId]
  );

  const handleResetNodes = () => {
    dispatch(graphActions.resetNodes());
  };

  const handleNodeChange = (event) => {
    setNodeId(event.target.value);
  };

  // Configure the chart options
  const chartOptions = {
    title: {
      text: "Node Relationship",
    },
    series: [
      {
        name: "Node Relationship",
        type: "graph",
        layout: "force",
        data: reduxNodes,
        links: reduxRelationships,
        roam: true,
        label: {
          position: "right",
          show: true,
        },
        force: {
          repulsion: 150,
          edgeLength: [50, 400],
        },
        emphasis: {
          focus: "adjacency",
          blurScope: "coordinateSystem",
          itemStyle: {
            borderColor: "#333",
            borderWidth: 2,
          },
        },
      },
    ],
  };

  return (
    <>
      {graphTestData &&
      "nodes" in graphTestData &&
      "relationships" in graphTestData ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
              px: { xs: 2, sm: 3 },
            }}
          >
            <Stack
              direction={{ xs: "row-reverse", sm: "column" }}
              gap={2}
              justifyContent='center'
              //   alignItems={{ xs: "center", sm: "flex-start" }}
              alignItems='center'
              sx={{ paddingTop: "10px" }}
            >
              <TextField
                // id='outlined-select-currency'
                select
                label={nodeId ? "Selected Node" : "Select Node"}
                // label='Select Node'
                onChange={handleNodeChange}
                value={nodeId}
                helperText={nodeId ? "" : "Please select Node"}
                sx={{
                  minWidth: "150px",
                  maxWidth: "200px",
                }}
              >
                {reduxFixedNodes
                  ? reduxFixedNodes.map((node) => {
                      return (
                        <MenuItem value={node.id} key={node.id}>
                          {node.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </TextField>
              <Button
                onClick={handleResetNodes}
                variant='outlined'
                sx={{ minWidth: "150px" }}
              >
                Reset
              </Button>
            </Stack>

            <EChartsReact
              option={chartOptions}
              style={{ height: "500px", width: "100%" }}
              onEvents={{
                click: handleNodeClick,
              }}
            />
          </Box>
        </>
      ) : (
        <>
          <CardWrapper data='Data is not suitable for Graph chart' />
        </>
      )}
    </>
  );
};

export default GraphChart;
