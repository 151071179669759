import React, { useState, memo, useEffect, useRef } from "react";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import GetCharts from "../GetCharts";
import { defaultElement } from "../../defaultElements";
import { getId } from "../../util";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { MdOutlineClear } from "react-icons/md";
import { AiFillPieChart } from "react-icons/ai";
import { chartOptions } from "../../util/chartOptions";
import {
  getChartHeading,
  getChartType,
  getQueryData,
  getNeoForeQueryData,
} from "../../apiServices/dashboard";
import CryptoJS from 'crypto-js';


// const dummyDataTry = [
//   ["p.productName", "NumberOfOrders"],
//   ["Raclette Courdavault", 54],
//   ["Guaraná Fantástica", 51],
//   ["Gorgonzola Telino", 51],
//   ["Camembert Pierrot", 51],
//   ["Gnocchi di nonna Alice", 50],
//   ["Tarte au sucre", 48],
//   ["Jack's New England Clam Chowder", 47],
//   ["Rhönbräu Klosterbier", 46],
//   ["Chang", 44],
//   ["Pavlova", 43],
//   ["Flotemysost", 42],
//   ["Boston Crab Meat", 41],
//   ["Konbu", 40],
//   ["Sir Rodney's Scones", 39],
//   ["Manjimup Dried Apples", 39],
//   ["Outback Lager", 39],
//   ["Lakkalikööri", 39],
//   ["Chai", 38],
//   ["Queso Cabrales", 38],
//   ["Mozzarella di Giovanni", 38],
//   ["Original Frankfurter grüne Soße", 38],
//   ["Alice Mutton", 37],
//   ["Teatime Chocolate Biscuits", 37],
//   ["Steeleye Stout", 36],
//   ["Tourtière", 36],
//   ["Scottish Longbreads", 34],
//   ["Ikura", 33],
//   ["Rössle Sauerkraut", 33],
//   ["Pâté chinois", 33],
//   ["Gumbär Gummibärchen", 32],
//   ["Thüringer Rostbratwurst", 32],
//   ["Nord-Ost Matjeshering", 32],
//   ["Geitost", 32],
//   ["Louisiana Fiery Hot Pepper Sauce", 32],
//   ["Inlagd Sill", 31],
//   ["Gudbrandsdalsost", 31],
//   ["Chartreuse verte", 30],
//   ["Singaporean Hokkien Fried Mee", 30],
//   ["Perth Pasties", 30],
//   ["Wimmers gute Semmelknödel", 30],
//   ["Uncle Bob's Organic Dried Pears", 29],
//   ["Filo Mix", 29],
//   ["Ipoh Coffee", 28],
//   ["Carnarvon Tigers", 27],
//   ["Spegesild", 27],
//   ["Côte de Blaye", 24],
//   ["Gula Malacca", 24],
//   ["Sirop d'érable", 24],
//   ["Ravioli Angelo", 23],
//   ["Tofu", 22],
//   ["Zaanse koeken", 21],
//   ["Maxilaku", 21],
//   ["Chef Anton's Cajun Seasoning", 20],
//   ["Tunnbröd", 20],
//   ["Sasquatch Ale", 19],
//   ["NuNuCa Nuß-Nougat-Creme", 18],
//   ["Escargots de Bourgogne", 18],
//   ["Vegie-spread", 17],
//   ["Sir Rodney's Marmalade", 16],
//   ["Mascarpone Fabioli", 15],
//   ["Queso Manchego La Pastora", 14],
//   ["Gustaf's Knäckebröd", 14],
//   ["Rogede sild", 14],
//   ["Röd Kaviar", 14],
//   ["Northwoods Cranberry Sauce", 13],
//   ["Longlife Tofu", 13],
//   ["Aniseed Syrup", 12],
//   ["Grandma's Boysenberry Spread", 12],
//   ["Chef Anton's Gumbo Mix", 10],
//   ["Valkoinen suklaa", 10],
//   ["Laughing Lumberjack Lager", 10],
//   ["Schoggi Schokolade", 9],
//   ["Louisiana Hot Spiced Okra", 8],
//   ["Genen Shouyu", 6],
//   ["Gravad lax", 6],
//   ["Chocolade", 6],
//   ["Mishi Kobe Niku", 5],
// ];

const MemoizedGetCharts = memo(GetCharts);

function AddChart({
  setIsAddChartOpened,
  data,
  setData,
  layouts,
  setLayouts,
  isAddChartOpened,
  defaultQuestion,
  setIsOptionExpanded,
}) {
  const [chartTheme, setChartTheme] = useState([
    { mainColor: "#6C69FF", gradientColor: "#3834FE" },
    { mainColor: "#2DE0D2", gradientColor: "#29CCBF" },
    { mainColor: "#EFDE41", gradientColor: "#FFFFFF" },
    { mainColor: "#29CCBF", gradientColor: "#2DE0D2" },
    { mainColor: "#F85F3E", gradientColor: "#FFFFFF" },
    { mainColor: "#3834FE", gradientColor: "#FFFFFF" },
    { mainColor: "#AE1AE1", gradientColor: "#FFFFFF" },
    { mainColor: "#85C236", gradientColor: "#FFFFFF" },
    { mainColor: "#670EAC", gradientColor: "#FFFFFF" },
  ]);
  const [theme, setTheme] = useState("light");
  const [question, setQuestion] = useState("");
  const [newChart, setNewChart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  // const [selectedYAxis,setselectedYAxis] = useState("")
  const [selectedXAxis, setselectedXAxis] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    newChart ? newChart.type : ""
  );

  console.log("newChart::", newChart);
  console.log("loading::", loading);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterChartRef = useRef(null);
  console.log("defaultQuestion::", defaultQuestion);
  console.log("add chart component is called");
  const dummyDefaultQuestion = [
    {
      question: "I want to see contracts expiring this month.",
    },
    {
      question: "I want pending contracts with each attorney",
    },
    {
      question:
        "What is the average heart rate for patients with high HDL cholesterol levels?",
    },
    {
      question:
        "Which country has the highest number of patients with a parent who had a myocardial infarction?",
    },
    {
      question: "What is the average BMI for patients in each state?",
    },
    {
      question: "How does the total cholesterol level vary by gender and city?",
    },
    {
      question:
        "What is the distribution of patients by Raynold risk score and country?",
    },
    {
      question: "How does the HDL cholesterol level vary by age and state?",
    },
    {
      question: "What is the average heart rate for patients in each city?",
    },
    {
      question:
        "Which state has the highest number of patients with high sensitivity C-reactive protein levels?",
    },
  ];
  const options = chartOptions;
  const key = CryptoJS.enc.Utf8.parse('abcdefghijklmnop'); 
  const decryptString = (encrypted) => {
    console.log("encrypted",encrypted.replace(/ /g, '+'))
    let str =encrypted.replace(/ /g, '+')
    let decryptedBytes = CryptoJS.AES.decrypt(str, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  };
  const generateChart = async (question) => {
    console.log("question::", question);
    setQuestion(question);
    setIsOptionExpanded(false);
    if (question.length === 0) {
      toast.error("Please provide proper question!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Close the toast after 3 seconds
      });
      return;
    }
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const encrypted = urlParams.get('encrypted'); 
    const decryptedMessage = decryptString(decodeURIComponent(encrypted));
    console.log("decrypt",decryptedMessage);
    const params = {};
  decryptedMessage.split('&').forEach(param => {
      const [key, value] = param.split('=');
      params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
  });
    const deptId  = params['deptid'];
    const neoforequeryDataRes = await getNeoForeQueryData(question,deptId);
    if(neoforequeryDataRes ==null || neoforequeryDataRes.length === 0){
      alert("Unable to execute the query. Please try a different query or contact support for assistance.")
      setLoading(false);
      return ;
    }
    // const neoforequeryDataRes = dummyDataTry;
    const rawData = neoforequeryDataRes;
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    
    // Example usage
    var NewDate = new Date();
    var lastUpdated = formatDate(NewDate);
    console.log("rawData:::::::::::::::::::",rawData)

    // const [keys, ...dataRows] = rawData;

    // const formattedData = dataRows.map((dataRow) => {
    //   const obj = {};
    //   keys.forEach((key, index) => {
    //     obj[key] = dataRow[index];
    //   });
    //   return obj;
    // });
    // console.log("formattedData::", formattedData);
    // const queryData = formattedData;

    // const getChartHeadingRes = await getChartHeading(question);
    
    const getChartHeadingRes = question;


    // console.log("getChartHeadingRes:::", getChartHeadingRes);
    // console.log("getChartHeadingRes:::::::in add chart::::", getChartHeadingRes)

    // let splitValue, otherKeys, countKey, dataKeys, chartProps, chartTypeResJson;
    // if (!queryData) {
    //   setYAxis([{ displayName: "", ogName: "" }]);
    //   setXAxis("value");
    //   chartProps = {
    //     xAxis: "value",
    //     yAxis: "type",
    //   };
    //   chartTypeResJson = {
    //     chartName: "CardChart",
    //   };
    // } else {
    //   // dataKeys = Object.keys(queryData[0]);
    //   dataKeys = rawData[0];
    //   // dataKeys = Object.keys(queryData[0]);
    //   console.log("dataKeys:::", dataKeys);

      // // not use of this code  no any use of x as is here but issue came at change x ax is data
      // countKey = dataKeys.find((key) => {
      //   splitValue = key.split(".");
      //   if (splitValue && splitValue[1]?.toLowerCase() === "count") {
      //     return key;
      //   }
      // });
      // setXAxis(countKey);
      // console.log("countKey::::::::", countKey);

      // otherKeys = dataKeys.filter((key) => {
      //   splitValue = key.split(".");
      //   if (
      //     splitValue &&
      //     splitValue[1].toLowerCase() !== "count" &&
      //     typeof queryData[0][key] !== "boolean"
      //   ) {
      //     return key;
      //   }
      // });
      // console.log("otherKeys::", otherKeys);

      // const modifingYaxis = otherKeys.map((keys) => {
      //   const splitedKeys = keys.split(".");
      //   return {
      //     displayName: splitedKeys[1],
      //     ogName: keys,
      //   };
      // });
      // console.log("modifingYaxis::", modifingYaxis);
      // setYAxis(modifingYaxis);

      // chartProps = {
      //   xAxis: otherKeys[0],
      //   yAxis: countKey,
      // };

      // chartProps = {
      //   xAxis: dataKeys[0],
      //   yAxis: dataKeys[1],
      // };

      // const chartTyperesData = await getChartType(formattedData, question);
      // const chartTyperesData = "DefaultBarChart";
      // chartTypeResJson = chartTyperesData;
      // console.log("chartTyperesData::", chartTyperesData);
    // }

    // let title = chartTypeResJson.chartName;
    let title = "DefaultBarChart";
    console.log("title::", title);

    let id = getId();
    let layout = defaultElement[title].layout;

    let item;
    item = {
      i: id,
      title: question,
      deptid:deptId,
      lastUpdated:lastUpdated,
      type: title,
      value: defaultElement[title].defaultValue,
      layout,
      chartProps : {},
      data: rawData,
      question: question,
    };
    // console.log("item::::::::::",JSON.stringify(item))
    setNewChart(item);
    setLoading(false);
  };

  const addToDashboard = () => {
    setData([...data, newChart]);
    const newLayouts = { ...newChart.layout, i: newChart.i };
   console.log("mddd:",layouts.md)
    if (layouts.lg.some((item) => item.x === 0 && item.y === 0)) {
      if (layouts.md != null && Array.isArray(layouts.md)) {
        console.log("mdddy:",layouts.md)
      layouts.md
        .map((item) => {
          if (item.x === 0) {
            return { y: item.y++, ...item };
          }
        })
      
        .concat(newLayouts);
      }
      setLayouts({
        ...layouts,
      });
    } else {
      setLayouts({ lg: layouts.lg.concat([newLayouts]) });
    }
    setIsAddChartOpened(false);
  };

  const handleYAxisChange = (event) => {
    const { ogName, displayName } = JSON.parse(event.target.value);
    // console.log("ogName::::::::::::::", ogName)
    // console.log("displayName::::::::::::::", displayName)

    setselectedXAxis();
    const newItem = {
      ...newChart,
      chartProps: { ...newChart.chartProps, xAxis: ogName },
    };
    setNewChart(newItem);
    // console.log("newItem:::::::::::", newItem)
  };

  const handleChartChange = (name) => {
    const newItem = { ...newChart, type: name };
    setNewChart(newItem);
    setIsFilterOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterChartRef.current &&
        !filterChartRef.current.contains(event.target)
      ) {
        setIsFilterOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className='addChartContainer'>
      <div
        className='closeAddChartContainer'
        onClick={() => setIsAddChartOpened(false)}
      ></div>
      <div className='addChartBodyContainer'>
        <div className='closeAddchartContainer'>
          <MdOutlineClear
            onClick={() => setIsAddChartOpened(false)}
            className='addchartcloseIcon'
          />
        </div>
        <div className='addChartBodyContainerInput addChartBodyContent'>
          <div className='addChartBodyinput'>
            <SearchRoundedIcon style={{ fontSize: "15px" }} />
            <input
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Let's create chart"
            />
            <MdOutlineClear
              className='addChartClear'
              onClick={() => {
                setNewChart(null);
                setQuestion("");
              }}
            />
            <Button
              variant='contained'
              className='addChartBodyContainerAddBtn'
              onClick={() => generateChart(question)}
            >
              Generate
            </Button>
          </div>
        </div>
        <div className='addChartBodyContainerChart addChartBodyContent'>
          {loading ? (
            <>
              <Loader />
            </>
          ) : newChart ? (
            <>
              <div className='addChartBodyFilter'>
                <div className='addChartBodyFilteroptions'>
                  {yAxis && yAxis.length > 1 && (
                    <select value={selectedXAxis} onChange={handleYAxisChange}>
                      {yAxis.map((axis, index) => (
                        <option key={index} value={JSON.stringify(axis)}>
                          {axis.displayName}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className='filterAddChartContainer' ref={filterChartRef}>
                  <Tooltip title='Chart Types' placement='right'>
                    <AiFillPieChart
                      style={{
                        fontSize: "20px",
                        color: "rgba(58, 0, 128, 0.742)",
                      }}
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                    />
                  </Tooltip>
                  {isFilterOpen && (
                    <div className='filterAddChartDropDown'>
                      {" "}
                      {/*hideChartFilter*/}
                      {options.map((option) => {
                        const { value, name } = option;
                        return (
                          <div
                            className='filterAddChartDropDownChild'
                            onClick={() => handleChartChange(value)}
                          >
                            {name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <MemoizedGetCharts
                data={newChart}
                index={0}
                theme={theme}
                chartTheme={chartTheme}
                editMode={false}
              />

              <div className='addChartBodyContainerAddBtnContainer'>
                <Button
                  variant='contained'
                  className='addChartBodyContainerAddBtn'
                  onClick={() => addToDashboard()}
                >
                  Add to dashboard
                </Button>
              </div>
            </>
          ) : (
            <div
              className={
                defaultQuestion
                  ? "addChartDefaultQuestionContainer"
                  : "defaultAddChartLoader"
              }
            >
              {/* dummyDefaultQuestion */}
              {/* {defaultQuestion ? (
                defaultQuestion.map((que) => { */}
              {dummyDefaultQuestion ? (
                dummyDefaultQuestion.map((que) => {
                  return (
                    <Tooltip title={que.question} placement='top'>
                      <div
                        className='addChartDefaultQuestion'
                        onClick={() => generateChart(que.question)}
                      >
                        {que.question.length < 41
                          ? que.question
                          : que.question.slice(0, 38) + "...."}
                      </div>
                    </Tooltip>
                  );
                })
              ) : (
                <div className='addChartDefaultQuestionContainerloader'>
                  <Loader />
                </div>
              )}
            </div>
          )}
        </div>

        {/* <div className='addChartBodyContainerAddBtn addChartBodyContent'>
                    <Button
                        variant="contained"
                    >
                        Add to dashboard
                    </Button>
                    
                </div> */}
      </div>
    </div>
  );
}

export default AddChart;

// const [newChart, setNewChart] = useState({
//   i: "43b3ad93-e143-4dd5-9c18-4db4c806ab85",
//   title: "Count of patients by country and state",
//   type: "DefaultBarChart",
//   value: "This is Default Bar Chart",
//   layout: { x: 0, y: 0, w: 5, h: 2 },
//   chartProps: { xAxis: "patientdb.country", yAxis: "patientdb.count" },
//   data: [
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Maharashtra",
//       "patientdb.count": "130",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Tamil Nadu",
//       "patientdb.count": "104",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Kerala",
//       "patientdb.count": "104",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Gujarat",
//       "patientdb.count": "78",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "West Bengal",
//       "patientdb.count": "78",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Uttar Pradesh",
//       "patientdb.count": "78",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Karnataka",
//       "patientdb.count": "78",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Andhra Pradesh",
//       "patientdb.count": "52",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Telangana",
//       "patientdb.count": "52",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Punjab",
//       "patientdb.count": "52",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Haryana",
//       "patientdb.count": "52",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Madhya Pradesh",
//       "patientdb.count": "52",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Rajasthan",
//       "patientdb.count": "52",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Odisha",
//       "patientdb.count": "52",
//     },
//     {
//       "patientdb.country": "India",
//       "patientdb.state": "Delhi",
//       "patientdb.count": "26",
//     },
//   ],
//   question: "What is the count of patients by country and state?",
// });

// const chartTypeUrl = "http://localhost:3001/openai/chart/type";
// const chartTypeRes = await fetch(chartTypeUrl, {
//     method: "POST",
//     headers: {
//         "Content-Type": "application/json"
//     },
//     body: JSON.stringify({ data: queryData, question: question })
// })

// const chartTypeResJson = await chartTypeRes.json()
// console.log("chartTypeResJson::::::::::::::::", chartTypeResJson)

// let splitValue, otherKeys, countKey, dataKeys
// let chartProps

// // if (queryData[0].value === 0 && queryData[0].type === "") {
// //     setYAxis([{ displayName: "", ogName: "" }])
// //     setXAxis("value")
// //     chartProps = {
// //         xAxis: "value",
// //         yAxis: "type"
// //     }

// // }
// // else {
// dataKeys = Object.keys(queryData[0])
// countKey = dataKeys.find(key => {
//     splitValue = key.split(".")
//     if (splitValue && splitValue[1].toLowerCase() === "count") {
//         return key
//     }
// });
// setXAxis(countKey)
// console.log("countKey::::::::", countKey)

// otherKeys = dataKeys.filter(key => {
//     splitValue = key.split(".")
//     if (splitValue && splitValue[1].toLowerCase() !== "count" && (typeof queryData[0][key] !== 'boolean')) {
//         return key
//     }
// });

// const modifingYaxis = otherKeys.map((keys) => {
//     const splitedKeys = keys.split(".")
//     return {
//         displayName: splitedKeys[1],
//         ogName: keys
//     }
// })
// setYAxis(modifingYaxis)
// chartProps = {
//     xAxis: otherKeys[0],
//     yAxis: countKey
// }
// }

// const cleanData = await fetch("http://localhost:3001/openai/clean/data", {
//     method: "POST",
//     headers: {
//         "Content-Type": "application/json",
//     },
//     body: JSON.stringify({ data: queryData}),
// });

// let dataArray = await cleanData.json()
// console.log("dataArray:::::::::::",dataArray)
