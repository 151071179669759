import { Auth0Provider } from "@auth0/auth0-react";

const domain = "dev-y5rfyycvwongxbhk.us.auth0.com";
const clientId = "E2TKGVaSlvQFYWBOSVgSI2cXRWTChRmk";
const redirectUri = window.location.origin;

const auth0Config = {
  domain,
  clientId,
  redirectUri,
};

export const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      redirectUri={auth0Config.redirectUri}
    >
      {children}
    </Auth0Provider>
  );
};
