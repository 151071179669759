import React, { useEffect, useState } from "react";
import Dashboard from "./component/Dashboard/Dashboard";
import Login from "./component/LoginPage/Login";
import { DashboardProvider } from "./Contexts/DashboardContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import ShareDashboard from "./component/Share/ShareDashboard";
import { v4 as uuidv4 } from 'uuid';
import { getUser, saveUser } from './apiServices/dashboard';
import useDashboardContext from './Contexts/DashboardContext';

import "./App.css"
import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('abcdefghijklmnop'); 

const decryptString = (encrypted) => {
  console.log("encrypted",encrypted.replace(/ /g, '+'))
  let str =encrypted.replace(/ /g, '+')
  let decryptedBytes = CryptoJS.AES.decrypt(str, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
  return decryptedBytes.toString(CryptoJS.enc.Utf8);
};
const App = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
 
  const urlParams = new URLSearchParams(window.location.search);
  const encrypted = urlParams.get('encrypted'); 
 
  const decryptedMessage = decryptString(decodeURIComponent(encrypted));
  console.log("decrypt",decryptedMessage);
  const params = {};
decryptedMessage.split('&').forEach(param => {
    const [key, value] = param.split('=');
    params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
});
  const deptid  = params['deptid'];
  const sitename = params['sitename']; 
  const orgname = params['orgname']; 
  const username = params['user']; 
  const user_id =uuidv4().replace(/-/g, "");

// Format the username (assuming it removes spaces and converts to camelCase)
const formattedUsername = username
const {addUser} = useDashboardContext();
const [userDetails, setuserDetails] = useState([]);

const getUserDetails = async () => {
  const res = await getUser({
    email : formattedUsername,
    username : formattedUsername,
    token : "",
    user_id : user_id
})


setuserDetails(res)
}
useEffect(() =>{
  
  getUserDetails()
},[])
  useEffect(() =>{
    
    localStorage.setItem("isAuthenticated",JSON.stringify({isAuthenticated : true}))
            localStorage.setItem("user",JSON.stringify({
                name : formattedUsername,
                username : formattedUsername
            }))
          setIsUserLoggedIn(true)
          console.log('userDetails',userDetails)
          if(userDetails!=null && userDetails.msg =="User details retrieved successfully")
            {
              addUser({
                name : formattedUsername,
                username : formattedUsername,
                accessToken : ""
             })
             console.log("userdetailfound")
            } 
            else if(userDetails!=null && userDetails.msg=='User not found'){
              addUser({
                name : formattedUsername,
                username : formattedUsername,
                accessToken : ""
            })
            
            saveUser({
                email : formattedUsername,
                username : formattedUsername,
                token : "",
                user_id : user_id
            })
            console.log("userdetailnotfound")
            }
            else{
              localStorage.clear(); // Clear local storage
              setIsUserLoggedIn(false); // Set user logged in state to false
              // Optionally, redirect user to another page after logout
              //window.location.href = 'https://testcore.affablebpm.com/';
            }

  },[userDetails]);
  
  useEffect(() => {
    const storedAuth = localStorage.getItem("isAuthenticated");
    if (storedAuth) {
      const paredAuth = JSON.parse(storedAuth);
      setIsUserLoggedIn(paredAuth.isAuthenticated);
    }
  }, [isUserLoggedIn]);

  return (
    <div className="app">
      <DashboardProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                !isUserLoggedIn ? (
                  <Login setIsUserLoggedIn={setIsUserLoggedIn} />
                ) : (
                  <Navigate to={`/dashboard?encrypted=${encrypted}`} replace />
                )
              }
            />
            <Route
              path="/dashboard"
              element={
                isUserLoggedIn ? (
                  <Dashboard setIsUserLoggedIn={setIsUserLoggedIn} />
                ) : (
                  <Navigate to={`/dashboard?encrypted=${encrypted}`} replace />
                )
              }
            />
             
            <Route
              path="*"
              element={
                isUserLoggedIn ? (
                  <Navigate to={`/dashboard?encrypted=${encrypted}`} replace />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route path="/dashboard/view/:650199d45e9c254657a1a1eb" element={<ShareDashboard />} />
          </Routes>
        </Router>
      </DashboardProvider>
    </div>
  );
};

export default App;

