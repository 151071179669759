import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function TableView({ data }) {

  const [tableData, setTableData] = useState(data.data)
  const [tableHeader, setTableHeader] = useState(null)


  var dataArray = data.data.map((item) => {
    return { x: item['x'], y: item['y'] };
  });
  

  const handleTableData = () => {
    setTableData(dataArray)
    setTableHeader([data.data[0].xAxisName, data.data[0].yAxisName])
  }
  useEffect(() => {
    tableData && tableData.length > 0 && handleTableData()
  }, [tableData])

  return (
    <TableContainer component={Paper} style={{ height: "110%", width: "100%" }}>
      <Table sx={{ height: "100%", width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {
              tableHeader && tableHeader.map((head) => {
                return <TableCell style={{backgroundColor: "whitesmoke", color: "black"}} >{head}</TableCell>
              })
            }
           
          </TableRow>
        </TableHead>
        <TableBody>
          {
            tableData && tableData.map((tData, index) => {
              return <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell key={index} style={{color: "rgba(0, 0, 0, 0.828)"}}>{tData.x}</TableCell>
                <TableCell key={index} style={{color: "rgba(0, 0, 0, 0.828)"}}>{tData.y}</TableCell>
              </TableRow>
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableView