import React from 'react'
import { Tooltip } from "@mui/material";
import { AiFillSave, AiOutlinePlus,AiOutlineReload } from 'react-icons/ai';


function AddChartTabs({setIsAddChartOpened, isAddChartOpened, handleDashboardSave, defaultQuestion ,getDefaultQuestions,getRefreshDashboardData}) {
   console.log("getRefreshDashboardData",getRefreshDashboardData)
    return (
        <div className="new-dashboard">
            <Tooltip title="Add Chart" placement="top">
                <div className="BodyIconContainer" onClick={() => {setIsAddChartOpened(!isAddChartOpened); (!defaultQuestion || (defaultQuestion && defaultQuestion.length ===0)) && getDefaultQuestions()}}>
                    <AiOutlinePlus className="bodyAddChartIcon bodyFilterIcon" />
                </div>
            </Tooltip>
            <Tooltip title="Save" placement="top">
                <div className="BodyIconContainer" onClick={() => handleDashboardSave()}>
                    <AiFillSave className="bodySaveIcon bodyFilterIcon" />
                </div>
           </Tooltip>
            <Tooltip title="Refresh" placement="top">
                <div className="BodyIconContainer" onClick={() => getRefreshDashboardData()}>
                    <AiOutlineReload className="bodySaveIcon bodyFilterIcon" />
                </div>

            </Tooltip>
        </div>
    )
}

export default AddChartTabs