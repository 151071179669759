import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Grid,Typography } from '@mui/material'
import { green, red } from '@mui/material/colors';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import data from '../../util/TestData.json';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    section: {
      margin: theme.spacing(2, 0),
    },
    pageLabel: {
      color: '#000000',
      marginBottom: theme.spacing(1),
      textTransform: "uppercase",
    },
    pageHeader: {
      color: '#000000',
      marginBottom: theme.spacing(2),
      textTransform: "capitalize",
    },
    responsiveImg: {
      width: "100%",
      height: "auto",
    },
    cardImage: {
      maxHeight: "150px !important",
      overflowY: "hidden",
    },
  
    // dashboard
    cardLabel: {
      color: '#000000',
      margin: theme.spacing(2, 0),
      textTransform: "uppercase",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        margin: theme.spacing(1, 0),
      },
    },
    cardHeader: {
      color: '#000000',
      margin: theme.spacing(2, 0),
      textTransform: "capitalize",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.8rem",
        margin: theme.spacing(1, 0),
      },
    },
    displayCard: {
      position: "relative",
      padding: "0px !important",
      minHeight: "140px",
      height: "auto",
    },
    displayCardGraph: {
      width: "100%",
      height: "60px !important",
      position: "absolute",
      bottom: "0px",
      left: "0px",
      [theme.breakpoints.down("xs")]: {
        height: "45px !important",
      },
    },
    ratio: {
      position: "absolute",
      top: "50%",
      left: "33%",
      [theme.breakpoints.down("xs")]: {
        top: "45%",
        left: "25%",
      },
    },
    progressbarContainer: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "250px",
    },
    //userOverview section
    cardTitle: {
      color: '#000000',
    },
    generalGraph: {
      width: "100%",
      height: "300px",
    },
  
    //footer
    footer: {
      padding: "8px 24px 16px 270px",
      [theme.breakpoints.down("sm")]: {
        padding: "8px 24px 16px 24px",
      },
    },
  }));
export default function BigNumberChart(props) {
    const [hasFetched, setHasFetched] = useState(false);
    const classes = useStyles();
  var chartData = props.chartData;

    const DisplayData = [
        {
          label: "Total Counts",
          value: data.totalCount,
          icon: <ArrowDropUpIcon />,
        },
       
        {
          label: "Counts",
          value:data.count,
          icon: <ArrowDropDownIcon />,
        },
       
      ];
    
    
     

  return (
   <>
     <Grid container spacing={1} className={classes.section}>
        {DisplayData.map((item, i) => (
          <Grid key={i} item xs={6} sm={3} md={3}>
            <Card>
              <CardContent className={classes.displayCard}>
                <canvas
                  id={item.label}
                  className={classes.displayCardGraph}></canvas>
                <Box className={classes.cardDataContent}>
                  <Typography
                    variant='subtitle2'
                    className={classes.cardLabel}
                    gutterBottom={true}>
                    {item.label}
                  </Typography>
                  <Typography
                    variant='h4'
                    component='h2'
                    className={classes.cardHeader}>
                    {item.value}
                  </Typography>
                  <Box className={classes.ratio}>
                    <Button
                      startIcon={item.icon}
                      size='small'
                      style={{
                        color: item.label[0] === "P" ? green[700] : red[400],
                        fontSize: "1.1rem",
                      }}>
                      {item.iconLabel}
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
   </>
  )
}
