import EChartsReact from "echarts-for-react";
import { setLineChartOption, setOption } from "../../services/ChartService";
import { generateDataForBarChart } from "../../services/DataService";
import * as echarts from "echarts";
import myData from "../../util/NewData.json";
import jsonData from "../../util/ReportRiskMatrixTest.json";
import { getRowColMaping } from "../../services/DataService";

export default function TreeChart(props) {
  let chartModelColor = [];
  var mapping = getRowColMaping(jsonData);
  // mapping.forEach(function (datum, index) {
  //   datum.collapsed = props.collapse;
  // });
  let myData = {
    name: "analytics",
    children: mapping,
  };
  // myData.children.forEach(function (datum, index) {
  //   datum.collapsed = props.collapse;
  // });

  chartModelColor = props.chartTheme;
  var chartType = "tree";
  var title = "";

  var chartData = props.chartData;
  let yAxisData = {
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.25,
        color: props.theme === "light" ? "#4F4F7935" : "#9090BB35",
      },
    },
  };
  var height = mapping.length * 35;
  var width = 3 * 380;
  var option = {};
  var option = {
    id: "heatmap",
    title: {
      text: title,
      textStyle: {
        fontSize: "15px",
        color: props.theme === "light" ? "#4F4F79" : "#9090BB",
      },
    },
    tooltip: {
      trigger: "item",
      triggerOn: "mousemove",
    },
    //   xAxis: {},
    //     yAxis: {},
    grid: {
      left: 0,
      top: 0,
    },
    series: [
      {
        type: "tree",
        data: [myData],
        top: "1%",
        left: "7%",
        bottom: "1%",
        right: "20%",
        symbolSize: 7,
        label: {
          position: "left",
          verticalAlign: "middle",
          align: "right",
          fontSize: 12,
          fontFamily: "Open Sans",
          color: props.theme === "light" ? "#000" : "#757595",
          fontWeight: 600,
        },
        leaves: {
          label: {
            position: "right",
            verticalAlign: "middle",
            align: "left",
            fontSize: 10,
            fontFamily: "Open Sans",
            color: props.theme === "light" ? "#000" : "#757595",
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: chartModelColor[1].mainColor,
              },
              {
                offset: 1,
                color: chartModelColor[0].gradientColor,
              },
            ]),
          },
        },
        emphasis: {
          focus: "descendant",
        },
        animationDurationUpdate: 1000,
        expandAndCollapse: true,
        // collapsed:props.collapse,
        animationDuration: 0,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: chartModelColor[1].mainColor,
            },
            {
              offset: 1,
              color: chartModelColor[0].gradientColor,
            },
          ]),
        },
        lineStyle: {
          // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //   {
          //     offset: 0,
          //     color: chartModelColor[1].mainColor,
          //   },
          //   {
          //     offset: 1,
          //     color: chartModelColor[0].gradientColor,
          //   },

          // ]),
          color: "#BEBEBE",
        },
      },
    ],
  };

  return (
    <>
      <EChartsReact option={option} style={{ height: height, width: width }} />
    </>
  );
}
