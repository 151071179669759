import { createContext,useContext,useReducer } from "react";
import dashboardReducer,{initialState} from "../Reducer/DashboardReducer";


const DashboardContext = createContext(initialState)

export const DashboardProvider = ({children}) =>{
    const [state,dispatch] = useReducer(dashboardReducer,initialState)

    const saveDashboard = (value) =>{
        dispatch({
            type : "SAVE_DASHBOARD",
            payload : {
                dashboardData : value
            }
        })
    }

    const addUser = (value) =>{
        console.log("value:::::::::::::::::::::::::::",value)
        dispatch({
          type : "ADD_USER",
          payload : {
            userData  :value
          }  
        })
    }
    const isSaveClicked = (value)=>{
        dispatch({
            type: "SAVE_BUTTON_CLICKED",
            payload : {
                isDashboardSaved : value
            }
        })
    }

    const value = {
        dashboardData : state.dashboardData,
        userData : state.userData,
        isDashboardSaved:state.isDashboardSaved,
        saveDashboard,
        addUser,
        isSaveClicked
    }

    return <DashboardContext.Provider value={value}>
        {children}
    </DashboardContext.Provider>
}


const useDashboardContext = () =>{
    const context = useContext(DashboardContext)
    if(context === undefined){
        throw new Error("useDashboardContext must be within DasboardContext")
    }
    return context
}

export default useDashboardContext