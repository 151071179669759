export const defaultElement = {
  WebPage: {
    defaultContainerProps: {
      isDraggable: true,
      isResizable: true,
      items: 5,
      rowHeight: 30,
      preventCollision: false,
      containerPadding: [0, 0],
      margin: [0, 0],
      cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
      breakpoints: { lg: 1200, md: 900, sm: 600, xs: 320, xxs: 0 },
      autoSize: true,
    },
    childElements: [],
  },
  BarDrillDown: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is BarDrillDown graph",
  },
  BarWithBack: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is BarWithBackground graph",
  },
  BasicLineChart: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is BasicLineChart graph",
  },
  CascadeChart: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is CascadeChart graph",
  },
  AreaChart: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is AreaChart graph",
  },
  SimpleGuage: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is Simple Guage graph",
  },
  DefaultBarChart: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is Default Bar Chart",
  },
  DefaultBubbleChart: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is Default Bubble Chart",
  },
  DefaultGradientStackedChart: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is Default Gradient Stacked Chart",
  },
  DefaultPieChart: {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is Default Pie Chart",
  },
  BigNumberChart: {
    layout: { x: 0, y: 0, w: 5, h: 1.5 },
    defaultValue: "This is Big Number Chart",
  },
  TreeChart: {
    layout: { x: 0, y: 0, w: 10, h: 3 },
    defaultValue: "This is Tree Chart",
  },
  BasicScatterChart: {
    layout: { x: 0, y: 0, w: 10, h: 3 },
    defaultValue: "This is Basic Scatter Chart",
  },
  Heatmap: {
    layout: { x: 0, y: 0, w: 10, h: 3 },
    defaultValue: "This is Heatmap",
  },
  CardChart : {
    layout: { x: 0, y: 0, w: 5, h: 2 },
    defaultValue: "This is CardChart",
  },
  GraphChart: {
    layout: { x: 0, y: 0, w: 10, h: 3 },
    defaultValue: "This is Default Pie Chart",
  },
};
