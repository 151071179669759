import React, { useState,useEffect } from 'react'
import { IoMdHelp } from 'react-icons/io';
import { MdOutlineClear } from 'react-icons/md';

function Story({ story,heading }) {
    const [openStory, setOpenStory] = useState(false)
    const [newStory,setNewStory] = useState(null)

    console.log("story::::::::",JSON.stringify(story))
    useEffect(() => {
        if(story){
            const newStory = story.split("*")

            setNewStory(newStory.slice(1))
        }
    }, [story]);
    return (
        <div>
            <IoMdHelp style={{ fontSize: "20px", color: "rgba(58, 0, 128, 0.742)" }} onClick={()=>setOpenStory(true)}/>
            {
                // openStory &&
                <div className={openStory ? 'storyInnerContainer' : 'storyInnerContainer storyInnerContainerHidden'}>
                    <div className='storyCloser'>
                        <div className='storyHeading'>{heading}</div>
                        <MdOutlineClear style={{ fontSize: "20px", color: "rgba(58, 0, 128, 0.742)" }} onClick={()=>setOpenStory(false)}/>
                    </div>
                    <ul className='storyContent'>
                        {newStory && newStory.map((item)=>{
                            return <li>
                                {item}
                            </li>
                        })}
                    </ul>
                </div>
            }

        </div>
    )
}

export default Story