import EChartsReact from "echarts-for-react";
import { generateDataForDrillDOwn, generateJsonFromBody, groupByRow, generate2DArray, getSeriesData, getNameArray } from '../../services/DataService';
import  jsonData from "../../util/ReportRiskMatrixTest.json";
import * as echarts from 'echarts';

export default function Heatmap(props) {
  let chartModelColor = [];
  chartModelColor = props.chartTheme;
  var option = {};
  var chartType = "heatmap";
  var title = "";
  var titleColorTheme = props.theme === "light" ? "#4F4F79" : "#9090BB";
  var rowBody = generateJsonFromBody(jsonData.results.rows.heading, jsonData.results.rows.body);
  var colBody = generateJsonFromBody(jsonData.results.columns.heading, jsonData.results.columns.body);
  var graph2dArray = generate2DArray(jsonData.results.graphs["responsible.resource"], 2);
  var rowNameArray = getNameArray(rowBody);
  var colNameArray = getNameArray(colBody);
  const rows = rowNameArray;
// prettier-ignore
const columns = colNameArray;
// prettier-ignore
const data = graph2dArray
    .map(function (item) {
    return [item[0], item[1], 1];
});
var height = colNameArray.length*25;
var width = rowNameArray.length*27;
option = {
  title: {
    text: title,
    textStyle:{
      fontSize: '15px',
      color:props.theme === "light" ? "#4F4F79" : "#9090BB"
    }
  },
  tooltip:{
    show:true,
    showContent:true,
    trigger : 'item',
    axisPointer: {
      type: 'cross',
      axis:'x',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  } ,
  grid:{
    left:250,top:20, bottom:200,right:10
  },
 
  xAxis: {
    type: 'category',
    data: rows,
    nameTextStyle:{
      lineHeight:15
    }, 
   
    axisLabel: {
      show: true,
      rotate: 90,
      overflow:'truncate',
      width:200
    }
  },
  legend:{
    linestyle:{
      height:"10px"
    }
  },
  yAxis: {
    type: 'category',
    data: columns,
    splitArea: {
      show: false
    },
    axisLabel: {
      show: true,
      overflow:'truncate',
      width:200
    }
  },
  visualMap: {
    min: 0,
    max: 10,
    calculable: true,
    orient: 'horizontal',
    left: 'center',
    bottom: '15%'
  },
  // dataZoom: [
  //   {
  //     startValue: '0'
  //   },
  //   {
  //     type: 'inside'
  //   }
  // ],
  
 //zlevel:3,
  series: [
    {
      name: 'Process',
      type: 'heatmap',
      data: data.map((item) => {
        return {
          value: item,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: chartModelColor[1].mainColor,
              },
              {
                offset: 1,
                color: chartModelColor[0].gradientColor,
              },
            ])
          }
        }
      }),
      label: {
        show: false
      },
      // emphasis: {
      //   itemStyle: {
      //     color:"#000",
      //     borderColor:"#000",
      //     shadowBlur: 10,
      //     shadowColor: 'rgba(0, 0, 0, 0.5)'
      //   }
      // }
    }
  ]
};
  
  
  return (
    <>
      <EChartsReact style={{ height: "100%", width: "100%" }}
        option={option}
        
      />
    </>
  );
}
